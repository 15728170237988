import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"



export const BlueCoastlist = createAsyncThunk("blue-coast/list", async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
        reqbody = {

            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            

            "count": false,



        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "bclusers/bcl-lead-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});









const BlueCoastReducer = createSlice({
    name: 'bluecoastReducer',
    initialState: {

        submitting: false,
        loading: false,
        submitted: false,
        resMessage: null,
        successmsg: null,
        success: false,
        failed: false,
        bluecoastdata: [],

  



    },
    reducers: {
       
        clearBlueCoastList(state, action) {
            state.bluecoastdata = [];
           
        },
    },
    extraReducers: {

         /// #################### BlueCoast Listing ###############################
         [BlueCoastlist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [BlueCoastlist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [BlueCoastlist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.bluecoastdata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
               
            }
        },
        




    

})

export default BlueCoastReducer.reducer;
export const {clearBlueCoastList} = BlueCoastReducer.actions;