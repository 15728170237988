import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import store from "../../../store";
import { getInformationListData } from "./InformationReducer";
import "./Information.css";
// import './Training Material Centar.css'
import "./../Training/Training Material Centar/Training Material Centar.css";
import "../../../form/formComponents/formcomp.css";
import { Box } from "@mui/system";
import { Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const Informationtemplate = () => {
  const [previewfile, setPreviewfile] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  let infoLinksData = useSelector((state) =>
    state.informationReducer?.informationlistdata &&
    state.informationReducer?.informationlistdata.length > 0
      ? state.informationReducer.informationlistdata
      : []
  );

  useEffect(() => {
    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        // "added_for": addedForId
      },
      count: false,
      source: "",
    };

    store.dispatch(getInformationListData(reqbody));
  }, []);

  const handleOpen = (val) => {
    setPreviewfile(val);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPreviewfile(null);
  };

  const findfileextenssion = (val) => {
    console.log("val11", val);
    var re = /(?:\.([^.]+))?$/;

    let extension = re.exec(val)[1];
    console.log("val11==", extension);
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "webp" ||
        extension == "WEBP" ||
        extension == "png" ||
        extension == "PNG" ||
        extension == "jpg" ||
        extension == "JPG" ||
        extension == "jpeg" ||
        extension == "JPEG" ||
        extension == "gif" ||
        extension == "GIF" ||
        extension == "bmp" ||
        extension == "BMP" ||
        extension == "ico" ||
        extension == "ICO" ||
        extension == "svg" ||
        extension == "SVG" ||
        extension == "tif" ||
        extension == "TIF" ||
        extension == "tiff" ||
        extension == "TIFF")
    ) {
      return "img";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "mp4" ||
        extension == "MP4" ||
        extension == "mkv" ||
        extension == "MKV" ||
        extension == "wemb" ||
        extension == "WEMB" ||
        extension == "flv" ||
        extension == "FLV" ||
        extension == "avi" ||
        extension == "AVI" ||
        extension == "mov" ||
        extension == "MOV" ||
        extension == "swf" ||
        extension == "SWF" ||
        extension == "wmv" ||
        extension == "WMV")
    ) {
      return "video";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "mp3" ||
        extension == "MP3" ||
        extension == "aif" ||
        extension == "AIF" ||
        extension == "cda" ||
        extension == "CDA" ||
        extension == "mid" ||
        extension == "MID" ||
        extension == "mpa" ||
        extension == "MPA" ||
        extension == "ogg" ||
        extension == "OGG" ||
        extension == "wav" ||
        extension == "WAV" ||
        extension == "wma" ||
        extension == "WMA" ||
        extension == "wpl" ||
        extension == "WPL")
    ) {
      return "audio";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "pdf" ||
        extension == "PDF" ||
        extension == "tex" ||
        extension == "TEX" ||
        extension == "txt" ||
        extension == "TXT")
    ) {
      return "pdf";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "doc" ||
        extension == "DOC" ||
        extension == "docx" ||
        extension == "DOCX" ||
        extension == "xlsx" ||
        extension == "XLSX" ||
        extension == "xlsm" ||
        extension == "XLSM" ||
        extension == "pptx" ||
        extension == "PPTX" ||
        extension == "csv" ||
        extension == "CSV" ||
        extension == "xml" ||
        extension == "XML" ||
        extension == "odp" ||
        extension == "ODP" ||
        extension == "ppt" ||
        extension == "PPT" ||
        extension == "pps" ||
        extension == "PPS" ||
        extension == "ods" ||
        extension == "ODS" ||
        extension == "xls" ||
        extension == "XLS" ||
        extension == "odt" ||
        extension == "ODT" ||
        extension == "rtf" ||
        extension == "RTF")
    ) {
      return "doc";
    }
    // return false;
  };

  return (
    <>
      <div className="header_portion_information">
        <h2 className="header_title header_top_heading">
          {infoLinksData[0]?.title}
        </h2>
        <span className="header_description header_top_desc">
          {infoLinksData[0]?.description}
        </span>
        <div>
          {infoLinksData[0]?.file_link.map((data) => {
            console.log(
              "datanew=============================================================================>",
              infoLinksData[0]?.file_link
            );
            return (
              <>
                {/* {data?.type == "video-youtube" && (
                  <div className="main_video_wraper">
                    <div className="header_portion_information_video">
                      <div className="player-wrapper SD_video_wrapper">
                        <Box>
                          <ReactPlayer
                            className="react-player"
                            url={`https://www.youtube.com/watch?v=${data?.youtube.id}`}
                            width="100%"
                            height="100%"
                            controls={true}
                            light={data?.youtube?.thumbnail_link}

                            // playing={play}
                          />
                        </Box>
                        <div className="bottom_test_desc_wrp">
                          <h2 className="header_title">{data?.title}</h2>
                          <span className="header_description">
                            {data?.description}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                <div>
                  {data?.type == "video-youtube" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                              <div onClick={() => handleOpen(data)}><img src={data.youtube.thumbnail_link}/></div>
                              <div className="meterial_button_wrapper">
                                <button className='programButtonClass' onClick={() => handleOpen(data)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>

                {/* <div>
                  {data?.type == "video-vimeo" && (
                    <div className="main_video_wraper">
                      <div className="header_portion_information_video">
                        <div className="player-wrapper SD_video_wrapper">
                          <div>
                            <ReactPlayer
                              className="react-player"
                              url={data?.vimeo}
                              width="100%"
                              height="100%"
                              controls={true}
                              light={data?.youtube?.thumbnail_link}

                              // playing={play}
                            />
                            <div className="bottom_test_desc_wrp">
                              <h2 className="header_title">{data?.title}</h2>
                              <span className="header_description">
                                {data?.description}
                              </span>
                              <span>{data?.type}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}

              <div>
                  {data?.type == "video-vimeo" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div onClick={() => handleOpen(data)}><img src='https://e7.pngegg.com/pngimages/678/228/png-clipart-logo-vimeo-video-combo-blue-combo.png'/></div>
                          </div>
                              <div className="meterial_button_wrapper">
                                <button className='programButtonClass' onClick={() => handleOpen(data)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>

                {/* <div>
                  {data?.type == "video-link" && (
                    <div>
                      <div className="main_video_wraper">
                        <div className="header_portion_information_video">
                          <div className="player-wrapper SD_video_wrapper">
                            <ReactPlayer
                              className="react-player"
                              url={data?.video}
                              width="100%"
                              height="100%"
                              controls={true}
                              // light={data?.video}

                              // playing={play}
                            />
                            <div className="bottom_test_desc_wrp">
                              <h2 className="header_title">{data?.title}</h2>
                              <span className="header_description">
                                {data?.description}
                              </span>
                              <span>{data?.type}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}


              <div>
                  {data?.type == "video-link" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div className={`video type_block meteralblockk`} onClick={() => handleOpen(data)}></div>
                          </div>
                              <div className="meterial_button_wrapper">
                                <button className='programButtonClass' onClick={() => handleOpen(data)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>

                {/* <div>
                  {data?.type == "video-upload" && (
                    <div>
                      {" "}
                      <h2>{data?.title}</h2>
                      <span>{data?.description}</span>
                      <span>{data?.type}</span>
                      <h1>upload</h1>
                    </div>
                  )}
                </div> */}

              <div>
                  {data?.type == "video-upload" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div className={`video type_block meteralblockk`} onClick={() => handleOpen(data)}></div>
                          </div>
                              <div className="meterial_button_wrapper">
                                <button className='programButtonClass' onClick={() => handleOpen(data)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>

                {/* <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "video" && (
                      <div>
                        <div className="main_video_wraper">
                          <div className="header_portion_information_video">
                            <div className="player-wrapper">
                              <ReactPlayer
                                className="react-player"
                                url={data?.file?.url}
                                width="100%"
                                height="100%"
                                controls={true}
                                // light={data?.video}

                                // playing={play}
                              />
                              <div className="bottom_test_desc_wrp">
                                <h2 className="header_title">{data?.title}</h2>
                                <span className="header_description">
                                  {data?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div> */}
             <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "video" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                              <div className="icon_wrper">
                                <div className={`${findfileextenssion(data?.file?.url)} type_block meteralblockk`}></div>
                              </div>
                                <p className="titel_file">File Name :<span> {data?.file?.file_local_name}</span></p>
                              <div className="meterial_button_wrapper">
                                {/* <button className="programButtonClass">
                                  <a href={data?.file?.url} download target="_blank">
                                    Download
                                  </a>
                                </button> */}

                                <button className='programButtonClass' onClick={() => handleOpen(data?.file?.url)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>

                {/* <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "pdf" && (
                      <div>
                        <div className="main_video_wraper">
                          <div className="header_portion_information_video">
                            <div className="player-wrapper">
                            <embed src={data?.file?.url} width="1360px" height="2100px" class='docshowifram'/>
                              <div className="bottom_test_desc_wrp">
                                <h2 className="header_title">{data?.title}</h2>
                                <span className="header_description">
                                  {data?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div> */}

                <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "pdf" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                              <div className="icon_wrper">
                                <div className={`${findfileextenssion(data?.file?.url)} type_block meteralblockk`}></div>
                              </div>
                              {/* <div className='icon_wrper'> <div className='jpeg'></div></div> */}
                              <p className="titel_file">File Name :<span> {data?.file?.file_local_name}</span></p>
                              <div className="meterial_button_wrapper">
                                <button className="programButtonClass">
                                  <a href={data?.file?.url} download target="_blank">
                                    Download
                                  </a>
                                </button>

                                <button className='programButtonClass' onClick={() => handleOpen(data?.file?.url)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>

                {/* <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "doc" && (
                      <div>
                        <div className="main_video_wraper">
                          <div className="header_portion_information_video">
                            <div className="player-wrapper">
                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.file?.url}`} width='1366px' height='623px' frameborder='0' class='docshowifram'/>
                              <div className="bottom_test_desc_wrp">
                                <h2 className="header_title">{data?.title}</h2>
                                <span className="header_description">
                                  {data?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div> */}
                 <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "doc" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                              <div className="icon_wrper">
                                <div className={`${findfileextenssion(data?.file?.url)} type_block meteralblockk`}></div>
                              </div>
                              {/* <div className='icon_wrper'> <div className='jpeg'></div></div> */}
                              <p className="titel_file">File Name :<span> {data?.file?.file_local_name}</span></p>
                              <div className="meterial_button_wrapper">
                                <button className="programButtonClass">
                                  <a href={data?.file?.url} download target="_blank">
                                    Download
                                  </a>
                                </button>

                                <button className='programButtonClass' onClick={() => handleOpen(data?.file?.url)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>

                {/* <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "audio" && (
                      <div>
                        <div className="main_video_wraper">
                          <div className="header_portion_information_video">
                            <div className="player-wrapper">
                            <audio src={data?.file?.url} controls/>
                              <div className="bottom_test_desc_wrp">
                                <h2 className="header_title">{data?.title}</h2>
                                <span className="header_description">
                                  {data?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div> */}
                 <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "audio" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                              <div className="icon_wrper">
                                <div className={`${findfileextenssion(data?.file?.url)} type_block meteralblockk`}></div>
                              </div>
                              {/* <div className='icon_wrper'> <div className='jpeg'></div></div> */}
                              <p className="titel_file">File Name :<span> {data?.file?.file_local_name}</span></p>
                              <div className="meterial_button_wrapper">
                                <button className="programButtonClass">
                                  <a href={data?.file?.url} download target="_blank">
                                    Download
                                  </a>
                                </button>

                                <button className='programButtonClass' onClick={() => handleOpen(data?.file?.url)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>


                {/* <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "img" && (
                      <div>
                        <div className="main_video_wraper">
                          <div className="header_portion_information_video">
                            <div className="player-wrapper">
                            <img src={data?.file?.url} width="1000" height="500" />
                              <div className="bottom_test_desc_wrp">
                                <h2 className="header_title">{data?.title}</h2>
                                <span className="header_description">
                                  {data?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div> */}

              <div>
                  {data?.type == "file" &&
                    findfileextenssion(data?.file?.url) == "img" && (
                      <Grid container className="program_details" spacing={1}>
                        <div className="box_wraper">
                          {<Grid item lg={4}md={6}xs={12}className="titel_wrper block_wrper">
                              <div className="icon_wrper">
                                <div className={`jpg type_block meteralblockk`}></div>
                              </div>
                              {/* <div className='icon_wrper'> <div className='jpeg'></div></div> */}
                              <p className="titel_file">File Name :<span> {data?.file?.file_local_name}</span></p>
                              <div className="meterial_button_wrapper">
                                <button className="programButtonClass">
                                  <a href={data?.file?.url} download target="_blank">
                                    Download
                                  </a>
                                </button>

                                <button className='programButtonClass' onClick={() => handleOpen(data?.file?.url)}>Preview</button>
                              </div>
                            </Grid>
                          }
                        </div>
                      </Grid>
                    )}
                </div>
              </>
            );
          })}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=" modalBaseStyle "
      >
        <Box className="Preview_material modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={handleClose}
              // className="CloseModal"
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography id="modal-modal-title" variant="h6" component="h1">
            Preview {findfileextenssion(previewfile)}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="modaldatashow_wrapper">
              {findfileextenssion(previewfile) == "img" ? (
                <img width={550} height={500} src={previewfile} />
              ) : findfileextenssion(previewfile) === "video" ? (
                <ReactPlayer
                  url={previewfile}
                  controls={true}
                  // playing={true}
                />
              ) : findfileextenssion(previewfile) == "pdf" ? (
                <embed
                  src={previewfile}
                  class="docshowifram"
                  width="1366px"
                  height="623px"
                />
              ) : findfileextenssion(previewfile) == "doc" ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewfile}`}
                  width="1366px"
                  height="623px"
                  frameborder="0"
                  class="docshowifram"
                ></iframe>
              ) : findfileextenssion(previewfile) === "audio" ? (
                <ReactPlayer
                  url={previewfile}
                  controls={true}
                  // playing={true}
                />
              ) :previewfile && previewfile?.type  ==="video-youtube" ? <ReactPlayer
              className="react-player"
              url={`https://www.youtube.com/watch?v=${previewfile?.youtube?.id}`}
              width="100%"
              height="100%"
              controls={true}
            />: previewfile && previewfile?.type  ==="video-vimeo"? <ReactPlayer
            url={previewfile?.vimeo}
            controls={true}
            // playing={true}
          />:previewfile && previewfile?.type == "video-link"?<ReactPlayer
          url={previewfile?.video}
          controls={true}
          // playing={true}
        />:previewfile && previewfile?.type == "video-upload"?<ReactPlayer
        url={previewfile?.videofile?.url}
        controls={true}
        // playing={true}
      />: null}
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Informationtemplate;
