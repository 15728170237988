
import Typography from '@mui/material/Typography';
import ListingTable from "../../../../listing/listing";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import Drawer from '@mui/material/Drawer';
import store from "../../../../store";
// import AddUserDrawer from '../../../../commoncomponent/addUserDrawer/addUserDrawer';
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Grid, LinearProgress, List, Snackbar, Tooltip } from '@mui/material';
import { fetchUserData, setInitData } from '../fetchUserDataReducer';
import SnackBarComp from "../../../../commoncomponents/snackbar/snackBar"
import AddIcon from '@mui/icons-material/Add';
import '../../../../assets/List.css'
import {
    getusersList,
    userManagementDelete,
    userManagementMultiDelete,
    sendResetPassword,
    logMeIn,
    logInInfo,
    // getusersmultidelete,
    setSuccessAfterSubmit,
    // getusersmultiedit,
    userManagementResendWelcomeEmail,
    usermanagestatusUpdate,
    usermanageMultiStatusUpdate,
    fetchUserRoles,
    fetchUserRolesName,
    roundRobin,
    FetchHMSettingModal,
    FetchTagDetails,
    FetchTrainingStatus,
    FetchStages,
    fetchContract,
    rolesForUpgrade,
    fetchContractTemplate
    // resetPassword,
    // welcomeMail
} from "./userListReducer";

import {
    clearCustomButtonClicked,
    clearEditData,
    clearTablerowData,
    clearViewData,
    closeModal,
    openModal,
    resetRowActionBtnClicked,
    resetRowActionData,
    setEnableRowLoader,
    setReloadTableFlag,
    spinLoader,
    stopLoader,
    unmountTable,
} from "../../../../listing/listReducer";
import { Box, display } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import ConfirmationModal from '../../../../commoncomponents/modal/confirmationModal';
import PopupModal from '../../../../commoncomponents/modal/popupmodal';
import { resetresolve } from '../../../Layout/layoutReducer';
import { resolvefunction } from '../../../../helper/helperFunctions';
import MultiStatusChangeModal from '../../../../commoncomponents/modal/multipleStatusChnageModal';
import ConfigureRoleModal from './configureRoleModal';
import LastLoginModal from './lastloginmodal';
import ConfigureRoundRobinModal from './configureRoundRobinModal';
import ManageHmSettingModal from './manageHmSettingModal';
import ConfigureTagsModal from './configureTagsModal';
import TrainingStatusModal from './trainingStatusModal';
import ManageStageModal from './manageStageModal';
import UpgradeRoleModal from './upgradeRoleModal';
import ContactDrawer from './sendcontractDrawer';
import ContractViewModal from './contractViewModal';
import FollowUpcall from '../../LeadManagement/FollowUpcall';
import { getDatafollowUpCallList, gettagalldata, ShowStageList } from '../../LeadManagement/LeadReducer';
import { folderViewVisitLog } from '../folderView/folderViewReducer';
import { getEmailTeamplatedata } from '../../EmailTemplateManagement/EmailTemplateReducer';
import EmailSendDrower from '../../LeadManagement/EmailSendDrower';
import ShowStageListModal from '../../LeadManagement/ShowStageListModal';
import ShowTagListModal from '../../LeadManagement/ShowTagListModal';


// import LastLoginModal from './lastloginmodal';

const UserManagementList = () => {

    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//


    // ---- States ----
    // const [loading, setLoading] = useState(true);  //*****FOR LODING****//
    // const [userType, setUserType] = useState(true);  //-- for setting userType from cookie--//
    // const [editdata, setEditdata] = useState({}); //**** FOR EDIT DATA****//
    const [addDrawerOpen, setAddDrawerOpen] = useState(false);  //--- Open AddUserDrawer ----//
    const [snackFire, setSnackFire] = useState(false);  //--- Open SnackBar ----//
    const [confrimationMsg, setConfrimationMsg] = useState(null);  //--- ConfrimationMsg  ----//
    const [resMessage, setResMessage] = useState(null);  //--- resMessage  ----//
    const [endpointResStatus, setEndpointResStatus] = useState(null);  //--- resMessage  ----//
    const [btnType, setBtnType] = useState("");  //--- ButtonType Set  ----//
    const [toogle, setToogle] = useState(false);  //--- Toogle ----//
    const [popupToogle, setPopupToogle] = useState(false);  //--- Toogle ----//
    const [loginPopupToogle, setloginPopupToogle] = useState(false);  //--- Toogle ----//
    const [loginPopupData, setloginPopupData] = useState([]);  //--- Toogle ----//
    const [popupData, setPopupData] = useState([]);  //--- popupData ----//
    const [dataCopy, setDataCopy] = useState({});  //--- dataCopy  ----//
    const [modalResponse, setModalResponse] = useState(false);  //--- after Confirmtion modal cofirm button clicked ----//
    const [formSubmissionStateUserForm, setFormSubmissionStateUserForm] = useState(0) //**** FOR FORM SUBMIT ****//
    const [statusAction, setStatusAction] = useState(null);  //--- after Confirmtion modal cofirm button clicked ----//
    const [multiStatusModal, setMultiStatusModal] = useState(false);  //--- Toogle ----//
    const [configureRoleModal, setConfigureRoleModal] = useState(false);
    const [UserId, setUserId] = useState(null);
    const [rolesSet, setRoles] = useState([]);
    const [roundRobinModal, setRoundRobinModal] = useState(false);
    const [hmSettingModal, setHmSettingModal] = useState(false);
    const [tagManageModal, setTagManageModal] = useState(false);
    const [trainingDataModal, setTrainingDataModal] = useState(false);
    const [stageModal, setStageModal] = useState(false);
    const [contractDrawer, setContractDrawer] = useState(false);
    const [contractPreviewModal, setContractPreviewModal] = useState(false);
    const [upgradeRoleModal, setUpgradeRoleModal] = useState(false);
    const [followUpModal, setFollowUpModal] = useState(false);
    const [openDrowerForEmail, setOpenDrowerForEmail] = React.useState(false);
    const [multiMail, setMultiMail] = useState(false)
    const [multiIdData, setMultiIdData] = useState([])
    const [showstageList, setshowstageList] = useState(false);
    const [showstageListAction, setshowstageListAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//
    const [showtagListModal, setshowtagListModal] = useState(false);



    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    console.warn("<<<<<<< userinfo cookies from userlisting >>>>>>>>", userInfo)

    // ---- UseSelectors ----
    /*******************For Resolve****** */
    const resolved = useSelector(state => state.ResolveReducer?.resolved['/usermanagement'] ? state.ResolveReducer.resolved['/usermanagement'] : false)
    const users = useSelector((state) => state.userManagment?.usersList);
    const searchFormData = useSelector((state) =>
        state.formSlice?.formData?.searchForm
            ? state.formSlice.formData.searchForm
            : {}
    );

    // const usersToken = useSelector((state) => state.loggedinuser.usertoken)
    const usersToken = "ssdsd"

    // console.warn("<<< userinfocookies goes here >>>>", userInfo.userInfo._id)

    // --- UserTableList Call ---
    useEffect(() => {


        if (!resolved) {
            store.dispatch(getusersList(tableDataForUsers.current.reqBody));
        }
        return () => {
            dispatch(resetresolve())
            // dispatch(clearTrainingList())

            //whenever the component removes it will executes
        }
    }, []);




    useEffect(() => {
        tableDataForUsers.current.searchData.formData.fields.forEach((data) => {
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
            console.log("data1234", data, searchFormData);
        })
        // console.log("searchFormData", searchFormData);
    }, [JSON.stringify(searchFormData)]);



    // --- for selection of addUser button --- 
    const Btn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice.customButtonClicked?.userslist1?.adduser) ? state.tableSlice.customButtonClicked.userslist1.adduser : false);


    // ----- On editBtn trigger ----
    const editBtn = useSelector(state => (state.tableSlice.customButtonClicked?.userslist1 && state.tableSlice.customButtonClicked?.userslist1?.edittable9) ? state.tableSlice.customButtonClicked.userslist1.edittable9 : false);


    // ----- On chnageStatus Btn trigger ----
    const chnageStatus = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.change_status1) ? state.tableSlice?.customButtonClicked?.userslist1?.change_status1 : false);

    // ----- On viewBtnAction trigger ----
    const viewBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.viewBtnAction) ? state.tableSlice?.customButtonClicked?.userslist1?.viewBtnAction : false);


    // ----- On DataRef on every btn trigger ----
    const dataRef = useSelector(state => (state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1) ? state.tableSlice?.tempRowData?.userslist1 : {});

    // // ----- On DataRef on every btn trigger ----
    const multidataRef = useSelector(state => (state.tableSlice?.tempRowData && state.tableSlice?.multipleSelectionList?.userslist1) ? state.tableSlice?.multipleSelectionList?.userslist1 : []);

    // // ----- On multiple Status Btn trigger ----
    const mltStatusBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.editMultiple) ? state.tableSlice?.customButtonClicked?.userslist1?.editMultiple : false);


    // ----- On deleteBtn Btn trigger ----
    const deleteBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.deletetable1) ? state.tableSlice?.customButtonClicked?.userslist1?.deletetable1 : false);

    // ----- On deleteBtn Btn trigger ----
    const asignRoleBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.assignRole) ? state.tableSlice?.customButtonClicked?.userslist1?.assignRole : false);


    // // ----- On multiple deleteBtn Btn trigger ----
    const mltDeleteBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.deleteMultiple) ? state.tableSlice?.customButtonClicked?.userslist1?.deleteMultiple : false);

    // ----- On welcomeMail Btn trigger ----
    const welcomeMailBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.resendwelcomemail1) ? state.tableSlice?.customButtonClicked?.userslist1?.resendwelcomemail1 : false);

    // ----- On LoginInfo Btn trigger ----
    const loginInfoBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.logInDetailsBtn) ? state.tableSlice?.customButtonClicked?.userslist1?.logInDetailsBtn : false);

    // ------- sendResetPassword ------
    const sendResetPasswordBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.sendResetPassword) ? state.tableSlice?.customButtonClicked?.userslist1?.sendResetPassword : false);

    // ------- stageLifeCycleBtn ------
    const stageLifeCycleBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.stageLifeCycle) ? state.tableSlice?.customButtonClicked?.userslist1?.stageLifeCycle : false);

    // ------- tagLifeCycleBtn ------
    const tagLifeCycleBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.tagLifeCycle) ? state.tableSlice?.customButtonClicked?.userslist1?.tagLifeCycle : false);



    // ------- logMeInBtn ------
    const logMeInBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.logMeInBtn) ? state.tableSlice?.customButtonClicked?.userslist1?.logMeInBtn : false);

    // --- SendMultipleEmailBtn Clicked ------
    const sendMultiMailClicked = useSelector((state) => state.tableSlice.customButtonClicked?.userslist1?.sendMail_multiple);


    // --- SendEmailBtn Clicked ------
    const sendMailClicked = useSelector((state) => state.tableSlice.customButtonClicked?.userslist1?.sendMail);

    // ---- emailTemplateData
    const emailtemplateData = useSelector(state => (state.EmailTemplateReducer?.emailtemplateListData && state.EmailTemplateReducer?.emailtemplateListData.length > 0) ? state.EmailTemplateReducer.emailtemplateListData : []);

    const loaderForsendmail = useSelector(state => state.LeadReducer?.loaderForsendmail);

    const submistionstate = useSelector(state => state.LeadReducer?.submistionstate);



    // ------ folderViewBtn ------
    const folderViewBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.folderView) ? state.tableSlice?.customButtonClicked?.userslist1?.folderView : false);


    // ------- Fetched User Data ------
    const fetchData = useSelector(state => (state.fetchUserDataReducer?.fetchUserDataReducer
    ) ? state.fetchUserDataReducer.fetchUserDataReducer
        : null);


    // ------ Loading during any action -----
    const loader = useSelector(state => (state.userManagment?.loading
    ) ? state.userManagment.loading
        : false);


    // ------ endpoint response failed -----
    const endpointHitFailed = useSelector(state => (state.userManagment?.failed
    ) ? state.userManagment.failed
        : false);


    // ------ endpoint response success -----
    const endpointHitSuccess = useSelector(state => (state.userManagment?.success
    ) ? state.userManagment.success
        : false);

    // ------ successAction response -----
    const successAction = useSelector(state => (state.userManagment?.successAction
    ) ? state.userManagment.successAction
        : false);


    // ------ endpoint response success Msg-----
    const endpointResMsg = useSelector(state => (state.userManagment?.resMessage
    ) ? state.userManagment.resMessage
        : null);

    // ------ during endpoint hit loading status -----
    const endpointLoading = useSelector(state => (state.userManagment?.loading
    ) ? state.userManagment.loading
        : false);



    // ------ during endpoint hit loading status -----
    const endpointLoader = useSelector(state => (state.fetchUserDataReducer?.loading
    ) ? state.fetchUserDataReducer.loading
        : false);


    const loadinglist = useSelector(state => (state.userManagment?.loadinglist
    ) ? state.userManagment.loadinglist
        : false);

    const userRoles = useSelector(state => (state.userManagment?.userRoles
    ) ? state.userManagment.userRoles
        : []);

    // -------- LoginInfoData here -----
    const loginInfoData = useSelector(state => (state.userManagment?.logininfo
    ) ? state.userManagment.logininfo
        : null);

    const userNotLoginYetStatus = useSelector(state => (state.userManagment?.userNotLoginYet) ? state.userManagment?.userNotLoginYet : false)

    // ------- User Role names -------
    const userRoleNames = useSelector(state => (state.userManagment?.userRoleNames
    ) ? state.userManagment.userRoleNames
        : []);

    // ------- Role Added done  ------
    const roleAddedDone = useSelector(state => (state.userManagment?.roleAdded) ? state.userManagment.roleAdded : null);

    // ------ roundRobinBtn ------
    const roundRobinBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.Manage_Round_Robin) ? state.tableSlice?.customButtonClicked?.userslist1?.Manage_Round_Robin : false);

    // ------- User Role names -------
    const roundRobinData = useSelector(state => (state.userManagment?.roundRobinData
    ) ? state.userManagment.roundRobinData
        : null);

    // ------- roundRobinEdit Success -------
    const roundRobinEditSuccess = useSelector(state => (state.userManagment?.roundRobinSuccess) ? state.userManagment.roundRobinSuccess : false);


    // ------ roundRobinBtn ------
    const ManageHMSettingsBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.Manage_HM_Settings) ? state.tableSlice?.customButtonClicked?.userslist1?.Manage_HM_Settings : false);

    //---- FetchHMSettingModalData ----
    const FetchHMSettingModalData = useSelector(state => (state.userManagment?.HMSettingModalData) ? state.userManagment?.HMSettingModalData : null)

    //---- HMSettingModalStatus after submiting ----
    const HMSettingModalStatus = useSelector(state => (state.userManagment?.HMSettingModalStatus) ? state.userManagment?.HMSettingModalStatus : false)


    // ------ TagManageBtn ------
    const TagManageBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.Tag_Manage) ? state.tableSlice?.customButtonClicked?.userslist1?.Tag_Manage : false);


    // ------ TagManageData ------
    const TagManageModalData = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : []);

    // ------- onTagUpdateSucess
    const tagUpdated = useSelector(state => (state.userManagment?.tagUpdated) ? state.userManagment.tagUpdated : false)

    // ------- onStageUpdateSucess
    const stageUpdated = useSelector(state => (state.userManagment?.stageUpdated) ? state.userManagment.stageUpdated : false)


    // ------- sendContractBtn -------
    const sendContractBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1?.send_contract
    ) ? state.tableSlice.customButtonClicked.userslist1.send_contract
        : false);


    //-------- fetchContractData------
    const contractData = useSelector(state => (state.userManagment?.fetchContractData) ? state.userManagment.fetchContractData : [])


    // ------- trainingStatusBtn -------
    const trainingStatusBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1?.trainingStatus
    ) ? state.tableSlice.customButtonClicked.userslist1.trainingStatus
        : false);

    // --- after Endpoint Call TrainingData Fetched ----
    const trainingData = useSelector(state => (state.userManagment?.trainingData
    ) ? state.userManagment.trainingData
        : []);

    // --- after Endpoint Call userTrainingData Fetched ----
    const userTrainingData = useSelector(state => (state.userManagment?.userTrainingData
    ) ? state.userManagment.userTrainingData
        : null);


    // ------- ManageStateBtn-----
    const manageStageBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1?.manage_stage) ? state.tableSlice.customButtonClicked.userslist1.manage_stage : false)


    // ------- FetchedStateData----
    const fetchedStateData = useSelector(state => (state.userManagment?.FetchStages) ? state.userManagment.FetchStages : [])


    // ------- upgradeRoleBtn-----
    const upgradeRoleBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1?.upgrade_role) ? state.tableSlice.customButtonClicked.userslist1.upgrade_role : false)


    const userRolesNewData = useSelector(state => (state.userManagment?.userRolesNew
    ) ? state.userManagment.userRolesNew
        : []);

    // ------- contractTemplateData -------
    const contractTemplateData = useSelector(state => (state.userManagment?.contractTemplate) ? state.userManagment.contractTemplate : {});


    // ------ followup_call ------
    const followupCallBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.userslist1 && state.tableSlice?.customButtonClicked?.userslist1?.followup_call) ? state.tableSlice?.customButtonClicked?.userslist1?.followup_call : false);


    // --- FollowupCall listing ----
    const followUpCallList = useSelector(state => (state.LeadReducer?.followUpCallData && state.LeadReducer?.followUpCallData.length > 0) ? state.LeadReducer.followUpCallData : [])

    // ---- loaderForFollowup
    const loaderForFollowUp = useSelector(state => state.LeadReducer?.loaderForFollowUp)

    // ----- Data for StageLifeCycle modal ------
    const fetchStagelistData = useSelector(state => (state.LeadReducer?.ShowStageListData) ? state.LeadReducer.ShowStageListData : [])    // ------- FetchedStagelistData

    // ----- Data for taglifecycle modal ------
    const taglifecycledatas = useSelector((state) =>
        state.LeadReducer?.taglifecycledata &&
            state.LeadReducer?.taglifecycledata.length > 0
            ? state.LeadReducer?.taglifecycledata
            : []
    );


    //----- stageLifeCycleBtn click-----
    useEffect(() => {
        if (stageLifeCycleBtn) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----
            dispatch(ShowStageList({ "_id": dataRef._id }))
        }
    }, [stageLifeCycleBtn])
    // ----------------------------

    // ---------- on StageLifecycle Data fetch mmodal fire or snackbar fire ------
    useEffect(() => {
        if (fetchStagelistData.length > 0) {
            setshowstageList(true)
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: false })) //---- row loadder false----
        } else {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: false })) //---- row loadder false----
            setSnackFire(true)
            setResMessage("No Stage Assigned yet!")
            setEndpointResStatus("failed")
        }
    }, [JSON.stringify(fetchStagelistData)])
    // --------------------------------------------------------------------



    
    //----- tagLifeCycleBtn click -----
    useEffect(() => {
        if (tagLifeCycleBtn) {
            setDataCopy(dataRef)

            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----
            dispatch(gettagalldata({ "_id": dataRef._id }))
        }
    }, [tagLifeCycleBtn])
    // ----------------------------

    // ---------- on tagLifecycle Data fetch mmodal fire or snackbar fire ------
    useEffect(() => {
        if (taglifecycledatas.length>0) {
            setshowtagListModal(true)
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: false })) //---- row loadder false----
        } else {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: false })) //---- row loadder false----
            setSnackFire(true)
            setResMessage("No Tag Assigned yet!")
            setEndpointResStatus("failed")
        }
    }, [JSON.stringify(taglifecycledatas)])
    // -----------------------------------------------------------





    // ---- sendMultiMailClicked ------
    let multiId = [];

    useEffect(() => {
        if (sendMultiMailClicked) {
            setMultiMail(true)

            for (let i = 0; i < multidataRef?.length; i++) {
                multiId[i] = multidataRef[i]._id;
            }

            // console.warn("<<<<<<<<<<< sendMultiMailClicked >>>>>>>", copyArr)
            // console.warn("<<<<<<<<<<< setMultiMailData >>>>>>>", multiMailData)
        }
    }, [sendMultiMailClicked])


    useEffect(() => {
        if (multiId.length > 0) {

            console.warn("<<<<<<<<<<< multiId >>>>>>>", multiId)
            setMultiIdData(multiId)

            let reqbody = {
                "condition": {
                    "limit": 5,
                    "skip": 0
                },
                "sort": {
                    "field": "_id",
                    "type": "desc"
                },
                "searchcondition": {
                    // "added_for": addedForId
                    "roles": []

                },
                "count": false,
                "source": "",
            }

            store.dispatch(getEmailTeamplatedata(reqbody));

        }
    }, [multiId])

    // ------------------------




    /**********************For Email Send start********************************* */

    useEffect(() => {
        dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----


        if (sendMailClicked === true && dataRef?.unsubscribe_email !== 1) {

            let roles = Object.keys(dataRef?.roles).map((key) => key)

            // console.warn("<<<<<<< roles for email >>>>>>", roles)

            let reqbody = {
                "condition": {
                    "limit": 5,
                    "skip": 0
                },
                "sort": {
                    "field": "_id",
                    "type": "desc"
                },
                "searchcondition": {
                    // "added_for": addedForId
                    "roles": { $in: roles }

                },
                "project": {
                    "subject": 1
                },
                "count": false,
                "source": "",
            }

            setDataCopy(dataRef)
            store.dispatch(getEmailTeamplatedata(reqbody));
        }

        if (sendMailClicked === true && dataRef?.unsubscribe_email == 1) {
            setSnackFire(true)
            setResMessage("Mail to this person restricted due to mail unsubscribed!")
            setEndpointResStatus("failed")
        }


    }, [sendMailClicked])

    useEffect(() => {
        if (emailtemplateData.length > 0) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----

            setOpenDrowerForEmail(true)
        }
    }, [emailtemplateData])

    const closeDrower = () => {
        setOpenDrowerForEmail(false)
    };

    // --------------------------------------------


    // ----------- on followupCallBtn call endpoint call ---- 
    useEffect(() => {
        if (followupCallBtn == true) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----

            setDataCopy(dataRef)

            let reqbody = {
                "condition": {
                    "limit": 5,
                    "skip": 0
                },
                "sort": {
                    "field": "_id",
                    "type": "desc"
                },
                "searchcondition": {
                    "added_for": dataCopy._id
                },
                // "project": {},
                // "token": "",
                "count": false,
                "source": "",
            }

            dispatch(getDatafollowUpCallList(reqbody))
        }
    }, [followupCallBtn])


    // --- On Successful endpoint call followup listing open ---
    useEffect(() => {
        dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----

        if (followUpCallList.length > 0) {
            setFollowUpModal(true)
        }
    }, [followUpCallList])

    const closeModal = () => {
        setFollowUpModal(false)
    };

    // -------------------------------------
    // -------------------------------------



    //----- on upgradeRoleBtn click ----
    useEffect(() => {
        if (upgradeRoleBtn === true) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----
            setDataCopy(dataRef)
            dispatch(rolesForUpgrade(dataRef))
        }
    }, [upgradeRoleBtn])
    // ---------------------------------

    useEffect(() => {
        if (userRolesNewData.length > 0) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----
            setUpgradeRoleModal(true)
        }
    }, [userRolesNewData])



    // ------- On ManageStateBtn Fire -----
    useEffect(() => {

        if (manageStageBtn === true) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----

            let roles = Object.keys(dataRef?.roles).map((key) => key)

            setDataCopy(dataRef)


            const data = {
                "condition": {},
                "sort": {},
                "searchcondition": {
                    "added_for": dataRef._id,
                    "roles": { $in: roles },
                },
                "project": {
                    "subject": 1
                },
                "token": "",
                "count": false

            }
            dispatch(FetchStages(data))
        }
    }, [manageStageBtn])
    // -------------------------------


    // modal after fetchedStateData fetched -----
    useEffect(() => {

        if (fetchedStateData.length > 0) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----

            // setUserId(dataRef._id)
            setStageModal(true)
        }
    }, [fetchedStateData])

    //--- after Sucessfully Updated Stage ----
    useEffect(() => {
        if (stageUpdated) {
            dispatch(setReloadTableFlag({ tableId: "userslist1" }))
        }
    }, [stageUpdated])





    // ---- onTagUpdateSucess----
    useEffect(() => {
        if (tagUpdated == true) {
            dispatch(setReloadTableFlag({ tableId: "userslist1" }))
            setSnackFire(true)
            setResMessage("Tag Assigned Sucessfull!")
            setEndpointResStatus("success")

        }
    }, [tagUpdated])
    // -------


    // ------ trainingDataModal on ------
    useEffect(() => {
        if (trainingData.length > 0) {
            setTrainingDataModal(true)
        } else {
            setSnackFire(true)
            setResMessage("No Training Data Found!")
            setEndpointResStatus("failed")
        }
    }, [trainingData])
    //------------------

    // -------- on trainingStatusBtn click endpoint call done ----
    useEffect(() => {

        if (trainingStatusBtn) {

            const data = {
                "source": "lesson_list",
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "field": "_id",
                    "type": "desc"
                },
                "searchcondition": {
                    "user_id": dataRef._id
                },
                "project": {},
                "token": "",
                "email": ""
            }

            dispatch(FetchTrainingStatus(data))
        }
    }, [trainingStatusBtn])

    //-------------------


    //  if sendContractBtn triggred ---
    useEffect(() => {
        setContractPreviewModal(false)

        if (sendContractBtn) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----
            setDataCopy(dataRef)
            setContractPreviewModal(false)

            const data = {
                "condition": {},
                "sort": {
                    "field": "name",
                    "type": "asc"
                },
                "searchcondition": {
                    "status": 1,
                    "rep_only": 1
                },
                "project": { "name": 1 },
                "token": "",
                "data": { "user_id": dataRef._id }
            }

            dispatch(fetchContract(data))
        }
    }, [sendContractBtn])

    // ------------------------


    // ----- drawer opens or endpoint call and modal open ------ 
    useEffect(() => {
        if (contractData.length > 1) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----
            setContractPreviewModal(false)
            setContractDrawer(true)

        } else if (contractData.length == 1) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----
            setContractDrawer(false)
            setContractPreviewModal(false)

            console.warn("<<<<<<<<< contractData goes here >>>>>>>>>", contractData)

            const payload = {
                "searchcondition": {
                    "user_id": dataCopy._id,
                    "contract_id": contractData[0]._id
                }
            }
            dispatch(fetchContractTemplate(payload))

        }

    }, [contractData])



    useEffect(() => {
        if (contractTemplateData && Object.keys(contractTemplateData).length > 0 && contractTemplateData.status !== "error") {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----
            setContractPreviewModal(true)
        }
    }, [contractTemplateData])

    // ---------------------------------------------



    //  on sucessfully data fetch TAG Modal open
    useEffect(() => {
        if (TagManageModalData.length > 0) {
            setTagManageModal(true)

        }
    }, [TagManageModalData])
    //---------------------------


    // -------- tagManagementBtn -----
    useEffect(() => {
        if (TagManageBtn) {

            let roles = Object.keys(dataRef?.roles).map((key) => key)

            setUserId(dataRef._id)
            setDataCopy(dataRef)

            const data = {
                "_id": dataRef._id,
                "roles": { $in: roles },
                "project": {
                    "subject": 1
                },


            }

            dispatch(FetchTagDetails(data))
        }
    }, [TagManageBtn])

    // ---------------------


    // ------ After Sucessfull Submission HMSetting Change
    useEffect(() => {
        if (HMSettingModalStatus) {
            setHmSettingModal(false)
            setSnackFire(true)
            setResMessage("HM Setting Updated Sucessfull")
            setEndpointResStatus("success")
        }
    }, [HMSettingModalStatus])


    // ----- HMSettingModal Open After fetching data
    useEffect(() => {
        if (FetchHMSettingModalData !== null) {
            setHmSettingModal(true)
        }

    }, [FetchHMSettingModalData])


    // ----- HMSettingModal Data fetch on HMSettingBtn Click
    useEffect(() => {
        if (ManageHMSettingsBtn) {

            const data = {
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "field": "name",
                    "type": "asc"
                },
                "searchcondition": {
                    "_id": dataRef._id
                },
                "count": false
            }

            dispatch(FetchHMSettingModal(data))
        }
    }, [ManageHMSettingsBtn])
    // -----------------------------------------------------------------


    // ------- roundRobinEditSuccess
    useEffect(() => {
        if (roundRobinEditSuccess) {
            setRoundRobinModal(false)
            setSnackFire(true)
            setResMessage("Round Robin Updated Sucessfull")
            setEndpointResStatus("success")

        }
    }, [roundRobinEditSuccess])
    // ---------------


    // ------- if roundRobinData found after endpoint call 
    useEffect(() => {
        if (roundRobinData !== null) {
            setRoundRobinModal(true)
        }
    }, [roundRobinData])
    // ---------------


    // -- after click on roundRobinBtn -----
    useEffect(() => {
        if (roundRobinBtn) {

            const data = {
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "field": "name",
                    "type": "asc"
                },
                "searchcondition": {
                    "_id": dataRef._id
                },
                "count": false
            }


            dispatch(roundRobin(data))
        }
    }, [roundRobinBtn])
    // ----------------------------------



    // -- after sucessfully role assigned from userListing refresh the list -----
    useEffect(() => {
        if (roleAddedDone) {
            setTimeout(() => {
                dispatch(setReloadTableFlag({ tableId: "userslist1" }))
            }, 200);
        }
    }, [roleAddedDone])
    // ----------------------------------



    useEffect(() => {
        if (folderViewBtn) {
            setDataCopy(dataRef)
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: true })) //---- row loadder true----

            const payload = {
                "user_for": dataRef._id,
                "user_by": userInfo?.userinfo._id
            }
            dispatch(folderViewVisitLog(payload))
            resolvefunction(navigate, "/folderview?id=" + dataRef._id)

            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----

        }
    }, [folderViewBtn])



    useEffect(() => {
        if (userNotLoginYetStatus === true) {
            setloginPopupData(null);
            setloginPopupToogle(false);
            setSnackFire(true);
            setEndpointResStatus("failed");
            setResMessage("Login information not found")
        }
    }, [userNotLoginYetStatus])

    // ------ on fetched userLoginInfo ----
    useEffect(() => {

        if (loginInfoData !== null) {
            setSnackFire(false);
            setResMessage(null);
            setloginPopupData(loginInfoData);
            // setloginPopupToogle(true);
        }

    }, [loginInfoData])



    useEffect(() => {

        if (loginPopupData !== null) {
            setSnackFire(false);
            setResMessage(null);
            setloginPopupToogle(true);
        }

    }, [loginPopupData])



    //-- on Sucessfully delete or status update of any user table refresh 
    useEffect(() => {
        if (successAction === true) {
            setToogle(false)
            setMultiStatusModal(false)
            dispatch(setReloadTableFlag({ tableId: "userslist1" }))
            setSnackFire(true)
            setResMessage(endpointResMsg)
            setEndpointResStatus("success")
        }
    }, [successAction])
    // ---------------


    //   ------- if any endpoint hit sucess or failed ------
    useEffect(() => {
        if (endpointHitSuccess) {
            setSnackFire(true)
            setResMessage(endpointResMsg)
            setEndpointResStatus("success")
        }
    }, [endpointHitSuccess])

    useEffect(() => {
        if (endpointHitFailed) {
            setSnackFire(true)
            setResMessage(endpointResMsg)
            setEndpointResStatus("failed")
        }
    }, [endpointHitFailed])

    // ---------------------------
    // ---------------------


    // ---- LogMein Btn Trigger ------
    useEffect(() => {

        if (logMeInBtn === true) {

            setDataCopy(dataRef)
            setTimeout(() => {
                let payload = {
                    admin_id: userInfo?.userinfocookies?._id,
                    log_me_id: dataCopy?._id,
                }
                dispatch(logMeIn(payload))
            }, 500);
        }
    }, [logMeInBtn])
    //--------------------------
    //--------------------------



    // ------ viewBtn triigred -------
    useEffect(() => {
        if (viewBtn === true) {
            setDataCopy(dataRef)
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataRef._id, load: true })) //---- row loadder ----
            dispatch(fetchUserData(dataRef._id)) //-- fetchUserData endpoint call from this func --

        }
    }, [viewBtn])


    useEffect(() => {
        if (fetchData !== null && fetchData !== undefined) {
            dispatch(setEnableRowLoader({ tableId: "userslist1", rowId: dataCopy._id, load: false })) //---- row loadder false----
            setPopupData(fetchData)
            setPopupToogle(true)
        }
    }, [fetchData])

    // console.warn("<<<<< popup Data goes here>>>>>", popupData)
    // console.warn("<<<<< popupToogle goes here>>>>>", popupToogle)
    // ---------------------------
    // ---------------------------


    // ---- delte btn triggred setting the confirmation modal msg ------
    useEffect(() => {
        if (deleteBtn === true) {
            setBtnType("dltBtn")
            setConfrimationMsg("Are you sure you want to delete this record? This can't be undone.")
            setToogle(true)
            setDataCopy(dataRef)
        }
    }, [deleteBtn])


    // ---- asignRoleBtn btn clicked ------
    useEffect(() => {
        if (asignRoleBtn === true) {
            dispatch(fetchUserRoles(dataRef))
            setUserId(dataRef._id)
        }
    }, [asignRoleBtn])


    useEffect(() => {
        setSnackFire(false)
        // console.warn(" <<<< user role lenght >>>>", userRoles.length)
        if (userRoles.length > 0 && userRoles !== null) {

            setSnackFire(false)
            setConfigureRoleModal(true)

        } else if (userRoles == null) {

            setSnackFire(true)
            setResMessage("Roles not found!")
            setEndpointResStatus("failed")

        }
    }, [userRoles])

    // ------------------------------
    // ------------------------------


    // ---- welcomeMailBtn btn clicked ------
    useEffect(() => {
        if (welcomeMailBtn === true) {
            setBtnType("welcomeMailBtn")
            setDataCopy(dataRef)
            dispatch(userManagementResendWelcomeEmail(dataRef)) //-- fetchUserData endpoint call from this func --
        }
    }, [welcomeMailBtn])
    // ------------------------------
    // ------------------------------


    // ---- sendResetPasswordBtn btn clicked ------
    useEffect(() => {
        if (sendResetPasswordBtn === true) {
            setBtnType("sendResetPassword")
            setDataCopy(dataRef)
            dispatch(sendResetPassword(dataRef)) //-- fetchUserData endpoint call from this func --
        }
    }, [sendResetPasswordBtn])
    // ------------------------------
    // ------------------------------



    // -------- UeEffect for calling endpoints condinationally based on action btn clicked ------
    useEffect(() => {
        if (modalResponse === true) {
            if (btnType == "dltBtn") {

                dispatch(userManagementDelete(dataCopy)); //-- delete endpoint call from this func --

            } else if (btnType == "multidltBtn") {

                let copyArr = [];

                for (let i = 0; i < dataCopy.length; i++) {
                    copyArr[i] = dataCopy[i]._id;
                }

                dispatch(userManagementMultiDelete(copyArr)); //-- multi dlt endpoint call from this func --

            }
        }
    }, [modalResponse])
    // -------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------


    // --- Refresh Table on load ---
    useEffect(() => {
        setshowtagListModal(false)
        setshowstageList(false)
        setPopupToogle(false);
        setToogle(false);
        setSnackFire(false);
        setloginPopupToogle(false);
        setResMessage(null);
        setStatusAction(null);
        setloginPopupData(null);
        setConfigureRoleModal(false)

        // dispatch(setReloadTableFlag({ tableId: "userslist1" }))
        // dispatch(setSuccessAfterSubmit());
        const data = {
            "condition": {
                // "limit": ,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {},
            "project": { "name": 1, "slug": 1, "status": 1 },
            "token": "",
            "count": false
        }
        dispatch(fetchUserRolesName(data)); //Fetch user roles

        setRoundRobinModal(false)
        setHmSettingModal(false)
        setTagManageModal(false)
        setTrainingDataModal(false)
        setStageModal(false)
        setContractDrawer(false)
        setUpgradeRoleModal(false)
        setContractPreviewModal(false)
        setFollowUpModal(false)
        closeModal();
        setOpenDrowerForEmail(false)

        if (userInfo.userinfo?.main_role == "hr_manager") {

            const data = {
                id: 2,
                label: "Status",
                name: "status",
                type: 'select',
                multiple: false,
                values: [{ key: 1, name: "Active" }, { key: 0, name: "Inactive" }],
                sx: { m: 1, minWidth: 120 },
                className: 'inputblock2line',
                payloadFormat: { key: "status", param: "$in", options: "i" },

            }

            tableDataForUsers.current.searchData.formData.fields.splice(2, 1)
            tableDataForUsers.current.searchData.formData.fields.splice(2, 1, data)
        }

    }, []);
    // ----------


    // --- on AddUser button click drawer open ---
    useEffect(() => {
        if (Btn === true) {
            // openAddUserDrawer();
            navigate("/useraddedit")

        }
    }, [Btn]);

    // ----------


    // -- open addUserDrawer --
    function openAddUserDrawer() {
        setAddDrawerOpen(true);
    }
    // ----------



    // --- on AddUser button click drawer open ---
    useEffect(() => {
        if (editBtn === true) {
            // console.log("dataRef", dataRef)
            // switch (dataRef.type) {

            //     case "Practice":
            //         navigate("/addedit-practice?id=" + dataRef._id)
            //         break;

            //     case "Admin":
            //         navigate("/addedit-admin?id=" + dataRef._id)
            //         break;

            //     case "User":
            //         navigate("/addedit-user?id=" + dataRef._id)
            //         break;

            //     default:
            //         break;
            // }

            // navigate("/addedit-practice?id=" + dataRef._id)
            resolvefunction(navigate, "/useraddedit?id=" + dataRef._id)



        }
    }, [editBtn]);

    // -------------------------------------------
    // -------------------------------------------


    // ------- Multiple Delte icon Click ------ 
    useEffect(() => {
        if (mltDeleteBtn == true) {
            setDataCopy(multidataRef);
            setBtnType("multidltBtn")
            setConfrimationMsg("Are you sure you want to delete these record(s)? This can't be undone")
            setToogle(true)
        }
    }, [mltDeleteBtn])
    // --------------------------------


    // ----- loginInfo Btn triggrs ---- 
    useEffect(() => {
        // console.log("loginInfoBtn", loginInfoBtn)

        if (loginInfoBtn === true) {
            setDataCopy(dataRef)
            setSnackFire(false);
            setResMessage(null);

            dispatch(logInInfo(dataRef))


        }
    }, [loginInfoBtn])


    // ---- Single Status Chnage btn clicked ------
    useEffect(() => {
        if (chnageStatus === true) {
            setBtnType("singleStatusBtn")
            setConfrimationMsg("Are you sure you that want to change the status?")
            setMultiStatusModal(true)
            setDataCopy(dataRef)
        }

    }, [chnageStatus])
    // ------------------------------
    // ------------------------------



    // ------- Multiple Status Btn Click ------ 
    useEffect(() => {
        if (mltStatusBtn == true) {
            setDataCopy(multidataRef);
            setBtnType("mltStatusBtn")
            setConfrimationMsg("Are you sure you that want to change the status?")
            setMultiStatusModal(true)
        }
    }, [mltStatusBtn])
    // --------------------------------


    // -------- multiple status chnage modal action btn clicked ------
    useEffect(() => {
        if (statusAction !== null && statusAction !== undefined) {
            if (btnType == "mltStatusBtn") {

                let copyArr = [];

                for (let i = 0; i < dataCopy.length; i++) {
                    copyArr[i] = dataCopy[i]._id;
                }

                dispatch(usermanageMultiStatusUpdate({ copyArr, statusAction })); //--  multiple status endpoint call 

            } else if (btnType == "singleStatusBtn") {
                dispatch(usermanagestatusUpdate({ "dataCopy": dataCopy, "status": statusAction == 1 ? true : false })); //-- Single Status Update endpoint call from this func --

            }
        }
    }, [statusAction])
    // -------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------


    // ----- UserRoles injecting in user search dynamically ---------
    useEffect(() => {
        if (userRoleNames.length > 0) {
            const roles = userRoleNames.map((data) => {
                return { val: data?.slug, name: data?.name }
            })
            const feild = tableDataForUsers.current.searchData?.formData?.fields.find((data) => data.name === 'user_type')
            if (feild) {
                feild.values = roles
            }
            // setRoles()

        } else {
            const feild = tableDataForUsers.current.searchData?.formData?.fields.find((data) => data.name === 'user_type')

            if (feild) {
                feild.values = [{ name: "Roles not found" }]
            }
        }
    }, [userRoleNames])
    // -------------------------


    // -------------------------

    // ----- Table list for user -----
    var modifyTableHeaders =
        [
            { val: "name", name: "Name" },
            { val: "email", name: "E-mail" },
            { val: "phone", name: "Contact" },
            { val: "tag_names", name: "Tags" },
            { val: "stage_name", name: "Stage" },
            { val: "address", name: "Address" },
            { val: "training_percentage_html", name: "Training Complete (%)" },
            { val: "last_activity", name: "Last Activity" },
            { val: "roles_string", name: "Type" },
            { val: "status", customVal: { '1': "Active", '0': "Inactive" }, name: "Status" },
            { val: "createdon_datetime", name: "Created On", type: "datetime", format: "DD/MM/YYYY hh:mm:ss" },
            { val: "last_login_time", name: "Last Login", type: "datetime", format: "DD/MM/YYYY hh:mm:ss" },
            { val: "updatedon_datetime", name: "Updated On", type: "datetime", format: "DD/MM/YYYY hh:mm:ss" },

        ];

    let tableDataForUsers = useRef({
        tableId: "userslist1",
        tableTitle: userInfo?.userinfo?.main_role == "hr_manager" ? "Rep Listing" : "Users Listing",
        enableRowActionLoader: true,
        showReload: true,
        showFilter: true,
        adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "users/user-list-or-count",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "users/user-list-or-count",
        },

        reqBody: {

            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                "parent_id": userInfo?.userinfo?.roles?.is_admin !== 1 ? userInfo?.userinfo?._id : undefined, //-- list will show exculding this refrence user
                // "is_cron":{
                //     "$exists":false
                // }

            },
            // "project": {
            //     "name": 1,
            //     "email": 1,
            //     "phone": 1,
            //     "tag_names": 1,
            //     "address": 1,
            //     "city": 1,
            //     "training_percentage_html": 1,
            //     "roles_string": 1,
            //     "status": 1,
            //     "rr_btn": 1,
            //     "mhm_btn": 1,
            //     "last_login_time": 1,
            //     "createdon_datetime":1,

            // },
            "count": false,
            // "token": usersToken


        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["name", "type", "login_time", "createdon_datetime", "status", "email"],
        paginationType: "stackPagination",
        // customSort: true,
        customBtnHead: [
            {
                id: "deleteMultiple",
                type: "delete_icon",
                title: "Delete",
            },
            {
                id: "editMultiple",
                type: "toggle_on_icon",
                title: "Status Change",
            },

            {
                id: "sendMail_multiple",
                title: "Bulk Email",
                type: "forward_to_inbox_two_tone_icon",
                className: "forward_to_inbox_two_tone_icon",

            },

            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [

            {
                id: "stageLifeCycle",
                label: "Stage Lifecycle",
                type: "other",
                name: "view_day_icon",
                // condField: "rr_btn",
                // condVal: 1,
            },

            {
                id: "tagLifeCycle",
                label: "Tag Lifecycle",
                type: "other",
                name: "data_saver_off_icon",
                // condField: "rr_btn",
                // condVal: 1,
            },


            {
                id: "manageProgram",
                label: "Manage Programe",
                type: "other",
                name: "fact_check_icon",
                condField: "rr_btn",
                condVal: 1,
            },

            {
                id: "sendMail",
                label: "Send Email",
                type: "other",
                name: "forward_to_inbox_two_tone_icon",
                // condField: "contract_send_flag",
                // condVal: 1,
            },


            {
                id: "upgrade_role",
                type: "other",
                name: "moving_icon",
                label: "Upgrade Role",
                condField: "role_upgrade",
                condVal: 1,

            },

            {
                id: "manage_stage",
                type: "other",
                name: "clear_all_icon",
                label: "Manage Stage",
                // condField: "roles_array",
                // condVal: ["is_rep"],

            },
            {
                id: "send_contract",
                type: "other",
                name: "article_icon",
                label: "Send Contract",
                condField: "contract_send_flag",
                condVal: 1,

            },
            {
                id: "Tag_Manage",
                type: "other",
                name: "local_offer_icon",
                label: "Tag Manage",
                // condField: "roles_array",
                // condVal: ["hr_manager", "is_aer", "is_rep"],

            },
            {
                id: "Manage_HM_Settings",
                type: "other",
                name: "layers_icon",
                label: "Manage HM Settings",
                condField: "mhm_btn",
                condVal: 1,

            },
            {
                id: "Manage_Round_Robin",
                type: "other",
                name: "groups_icon",
                label: "Manage Round Robin",
                condField: "rr_btn",
                condVal: 1,

            },

            {
                id: "followup_call",
                type: "other",
                name: "follow_the_signs_icon",
                label: "Followup Call",
                condField: "contract_send_flag",
                condVal: 1,

            },

            {
                id: "trainingStatus",
                type: "other",
                name: "model_training_icon",
                label: "Training Status",
                // condField: "roles_array",
                // condVal: ["hr_manager", "is_aer", "is_rep"],


            },
            {
                id: "assignRole",
                type: "other",
                label: "Assign User Role",
                name: "settings_suggest_icon",
            },

            {
                id: "folderView",
                type: "other",
                label: "Folder View",
                name: "folder_copy_icon",
            },

            // {
            //     id: "deletetable1",
            //     type: "other",
            //     label: "Delete",
            //     name: "delete_icon",
            // },

            {
                id: "edittable9",
                type: "other",
                icon_type: "edit",
                label: "Edit",
                name: "edit",
            },
            {
                id: "resendwelcomemail1",
                type: "other",
                label: "Resend Welcome Mail",
                name: "markunread",
            },


            {
                id: "change_status1",
                type: "other",
                label: "Change Status",
                name: " toggle_off ",
            },
            {
                id: "sendResetPassword",
                type: "other",
                label: "Send Reset Password",
                name: "lockreset",
            },


            {
                id: "viewBtnAction",
                type: "other",
                name: "preview",
                label: "Preview",
            },

            {
                id: "logInDetailsBtn",
                type: "other",
                name: "info",
                label: "Login Info",
            },


            //  ########################### Notes ########################################
            {
                id: "notesBadge",// for unique id of button//
                label: "Notes",//on hover show//
                type: "note",//****button category */
                icon_type: "summarize_icon",//for the button icon from mui icons  https://mui.com/material-ui/material-icons///
                name: "note_inbuilt",//as your wish///
                className: "forminline",//for modal top level tag class name//
                count_key: 'notescount',//count of notes, view in badge, value should be in row data object//
                notes_for_key: "name",//modal title notes for dynamic data, value should be in row data object
                noteaddbaseurl: process.env.REACT_APP_API_URL,//for end point base path//
                noteaddendpoint: "note/add-note",//final path of end point//
                noteaddbody: {//default body to add notes//
                    "data": {
                        "notes_by": userInfo?.userinfo?._id,
                        "collection_name": 'users'
                    }
                },
                body: {
                    "source": "", "condition": { "limit": 5, "skip": 0 },
                    "sort": { "type": "desc", "field": "assigned_datetime" }, "searchcondition": {}
                },//for initial listing end point call body//
                tableData: {//config for notes table in side modal like normal table config//
                    tableId: "notesTable1",
                    mainTableId: "userslist1",
                    tableTitle: " Notes Table",
                    no_data_html: "<p>No Data Found </p>",
                    payload_key: "notes_for",
                    showReload: true,
                    showFilter: true,
                    api: {
                        url: process.env.REACT_APP_API_URL,
                        endPoint: "note/note-list-count",
                        deleteSingleUserEndpoint: "note/delete-notes",
                        tableCountEndpoint: "note/note-list-count",
                    },
                    reqBody: {
                        condition: {
                            limit: 5,
                            skip: 0,
                        },
                        searchcondition: {},
                        sort: {
                            type: "desc",
                            field: "createdon_datetime",
                        },
                    },
                    deleteSingleUserReqBody: {
                        source: "users",
                        secret: "AZ|lepL`",
                        token:
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    },
                    rowsPerPageOptions: [5, 10, 20, 30],
                    sortFields: ["notesby_names", "createdon_datetime", "notes"],
                    paginationType: "stackPagination", ////////////////////// By Default Rows per page option will be available////////////,
                    customBtnHead: [],
                    buttons: [
                        // {
                        //   id: "deletetable01",
                        //   type: "delete",
                        //   label: "Delete",
                        //   name: "delete",
                        //   condField: "notes_by",
                        //   condVal: loggedUserId,
                        // }

                        {
                            id: "delete",
                            label: "Multi Delete",
                            type: "api",
                            category: "modal",
                            condField: "notes_by",
                            condVal: userInfo?.userinfo._id,
                            icon_type: "delete",
                            className: "delete_data",
                            modalClassName: "delete_modal",
                            modalHeader: "<p> Alert !!</p>",
                            modalTitle: "<p>Are you sure you want to delete this record? This can't be undone.</p>",
                            btn_label: ['Yes', "No"],
                            api_data: {
                                base_url: process.env.REACT_APP_API_URL,
                                endpoint: "note/delete-notes",
                                body: {
                                    "source": "users",
                                    "secret": "AZ|lepL`",
                                    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                                }
                            }
                        },

                    ],
                    deleteModal: {
                        modalClassName: "Delete Note",
                    },

                    searchBarData: {
                        formWrapperClass: "backendTableWrapper",// Can be undefined default will be formCls
                        formInlineCLass: "backendTableInline test_one", // Can be undefined default will be formInline
                        formButtonClass: "submitbuttonwrapper test searchRefreshIconBtn",
                        endPointBodyWrapper: "searchcondition",
                        heading: "Search Notes",
                        class: "draWerp",

                        api: {
                            url: process.env.REACT_APP_API_URL,
                            endPoint: "note/note-list-count",
                            tableCountEndpoint: "note/note-list-count",
                            reqBody: {
                                source: "",
                                condition: {
                                    'limit': 5,
                                    'skip': 0

                                },
                                sort: {
                                    type: "desc",
                                    field: "_id",
                                },
                                "searchcondition": {

                                    // "notes_by": userInfo?.userinfo._id
                                }

                            },
                        },
                        searchSettings: {
                            datesearch: [
                                {
                                    id: 0,
                                    // heading: "Search By Joining Date",
                                    startdatelabel: "Added On",
                                    enddatelabel: "End Date",
                                    fullDay: true,
                                    className: "inputblock4line",
                                    field: "createdon_datetime",
                                },
                            ],

                            textsearch: [
                                {
                                    id: 1,
                                    //   heading: "Search By Note",
                                    label: "Search By Note",
                                    field: "notes",
                                    className: "inputblock4line",
                                    value: "",
                                },

                                // {
                                //     id: 2,
                                //     // heading: "Search Added By",
                                //     label: "Search Added By",
                                //     field: "added_name",
                                //     className: "inputblock4line",
                                //     value: "",
                                // }


                            ],
                            autoCompleteSearch: [
                                {
                                    id: 5,
                                    label: "Search Added By",
                                    field: "added_name",
                                    values: "",
                                    multiple: false,
                                    preload: true,
                                    className: 'form_item list_search_item countDiv__column--col6 "',
                                    serverSearchData: {

                                        base_url: process.env.REACT_APP_API_URL,
                                        endpoint: "api/autocompletenotestitle",
                                        reqBody: {
                                            "condition": {
                                                "limit": 10,
                                                "skip": 0
                                            },
                                            "sort": {
                                                "type": "desc",
                                                "field": "_id"
                                            },
                                            "searchcondition": {}
                                        },
                                    },
                                },



                            ],

                            selectsearch: [



                            ],
                        },
                    },

                },
                headers: [
                    { val: "notes", name: "Note" },
                    { val: "added_name", name: "Added By" },

                    { val: "createdon_datetime", name: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss" },
                ]
            },




            //************************************ ACTION BUTTON FOR LOGIN MODAL ************************************//

            {
                id: "logMeInBtn",
                type: "other",
                name: "login",
                label: "Log me in",
            },
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },

        searchData: {
            heading: userInfo?.userinfo?.main_role == "hr_manager" ? "Search Rep" : "Search User",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                // submitBtnEndIcon: "search",
                resetBtnName: "REFRESH",
                resetBtnEndIcon: "replay",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        // heading: "Search By Full Name",
                        label: "Search By Full Name ",
                        name: "name",
                        type: 'text',
                        className: "inputblock2line",
                        payloadFormat: { key: "name", param: "$regex", options: "i" },

                    },


                    {
                        id: 1,
                        // heading: "Search By Email",
                        label: "Search By Email ",
                        type: 'text',
                        name: "email",
                        className: "inputblock2line",
                        payloadFormat: { key: "email", param: "$regex", options: "i" },

                    },


                    {
                        id: 2,
                        label: "Search by User Types",
                        name: "user_type",
                        type: 'select',
                        multiple: true,
                        values: [],
                        sx: { m: 1, minWidth: 120 },
                        className: 'inputblock2line',
                        payloadFormat: { key: "user_type", param: "$in", options: "i" },

                    },

                    {
                        id: 3,
                        label: "Search by Start Date",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        // format: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
                        className: 'inputblock2line',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 4,
                        label: "Search by End Date",
                        name: "end_date",
                        className: 'inputblock2line',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        // format: 'dd/MM/yyyy'
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },


                ]
            }
        },

    });
    // -----------------------


    // injecting delete button in user listing based on user role -------
    useEffect(() => {
        const deletetable1 = {

            id: "deletetable1",
            type: "other",
            label: "Delete",
            name: "delete_icon",

        }

        if (userInfo.userinfo.main_role == 'is_super_admin' || userInfo.userinfo.main_role == 'is_developer' || userInfo.userinfo.other_role.includes('is_super_admin') || userInfo.userinfo.other_role.includes('is_developer')) {
            tableDataForUsers.current.buttons.splice(1, 0, deletetable1);
        }

    }, [])
    // ----------------------

    return (
        <>

            <div className='rolelist_mainwrp'>

                <div className='listing_heading'>

                    <h1>{userInfo?.userinfo?.main_role == "hr_manager" ? "Rep Management" : "User Management"}</h1>
                    <Tooltip title="ADD USER">
                        <button className='addrole_btn' onClick={() => navigate("/useraddedit")}>
                            <AddIcon />
                        </button>
                    </Tooltip>
                </div>
                <div className='userlisttable'>


                    {/* ----- Loader ---- */}
                    {/* {endpointLoading || endpointLoader ? (<Box sx={{
                        width: '100%', position: 'sticky',
                        top: '0px',
                        background: '#68aad7',
                        zIndex: 1,
                        padding: '10px'
                    }} className="loader">
                        <LinearProgress />
                    </Box>) : ""} */}
                    {/* ------------------- */}


                    {/* ---- taable of User Listing --- */}
                    <div className="reactTableWrapper">
                        <div className="adminlisttable_v2 table_Icons ">
                            {!loadinglist && users ? (
                                <ListingTable
                                    tableData={tableDataForUsers.current}
                                    dataset={users}
                                    modifyHeaders={modifyTableHeaders}
                                />
                            ) : (<TableSkeleton count={5} />)}
                        </div>
                    </div>
                </div>
            </div>


            {/* ------ Confirmation Modal ------ */}
            {toogle ? (<ConfirmationModal className="confirmaModal"
                confrimationMsg={confrimationMsg}
                toogle={toogle}
                tooglefunc={setToogle}
                setModalResponse={setModalResponse}
            />) : ""}
            {/* -------------------------------- */}


            {/* ------ Status Chnage Modal ------ */}
            {multiStatusModal ? (<MultiStatusChangeModal className="confirmaModal"
                confrimationMsg={confrimationMsg}
                toogle={multiStatusModal}
                tooglefunc={setMultiStatusModal}
                setModalResponse={setStatusAction}
            />) : ""}
            {/* -------------------------------- */}

            {/* ConfigureRoleModal */}
            {configureRoleModal && userRoles !== null ? (<ConfigureRoleModal
                toogle={configureRoleModal}
                tooglefunc={setConfigureRoleModal}
                setModalResponse={setStatusAction}
                userRoles={userRoles}
                UserId={UserId}
            />) : ""}



            {/* ------- Popup Modal for preview user details ------------ */}
            {fetchData ?
                (<PopupModal
                    popupdata={popupData}
                    popuptoogle={popupToogle}
                    popuptooglefunc={setPopupToogle}
                    type="profileInfo"
                />) : ""}
            {/* ----------------- */}


            {/* ---- configureRoundRobinModal------ */}
            {roundRobinModal ? (<ConfigureRoundRobinModal
                toogle={roundRobinModal}
                tooglefunc={setRoundRobinModal}
                setModalResponse={setStatusAction}
                data={roundRobinData}
            // UserId={roundRobinData._id}
            />) : ""}

            {/* ---------------------------- */}


            {/* ---- ManageHmSettingModal------ */}
            {hmSettingModal ? (<ManageHmSettingModal
                toogle={hmSettingModal}
                tooglefunc={setHmSettingModal}
                setModalResponse={setStatusAction}
                data={FetchHMSettingModalData}
            // UserId={UserId}
            />) : ""}

            {/* ---------------------------- */}


            {/* ---- ConfigureTagsModal------ */}
            {tagManageModal ? (<ConfigureTagsModal
                toogle={tagManageModal}
                tooglefunc={setTagManageModal}
                setModalResponse={setStatusAction}
                tags={TagManageModalData}
                UserId={UserId}
                Userinfo={dataCopy}
            />) : ""}

            {/* ---------------------------- */}



            {/* ------ trainingStatusModal ----- */}
            {trainingDataModal ? (<TrainingStatusModal
                popupdata={trainingData}
                popuptoogle={trainingDataModal}
                userTrainingData={userTrainingData}
                popuptooglefunc={setTrainingDataModal}
            //   type="profileInfo"
            />) : ""
            }
            {/* --------------------------------- */}



            {/* -------- ManageStageModal ------ */}
            {stageModal ? (<ManageStageModal
                toogle={stageModal}
                tooglefunc={setStageModal}
                stage={stageModal}
                UserId={dataCopy._id}
                Userinfo={dataCopy}
            />) : ''}
            {/* ---------------------- */}


            {/* -------- UpgradeRoleModal ------ */}
            {userRolesNewData.length > 0 ? (<UpgradeRoleModal
                open={upgradeRoleModal}
                tooglefunc={setUpgradeRoleModal}
                role={userRolesNewData}
                userId={dataCopy._id}

            />) : ''}
            {/* ---------------------- */}


            {/* ---- Drawer for send contract ---- */}
            {contractDrawer ? (<ContactDrawer
                open={contractDrawer}
                setcontractdrawer={setContractDrawer}
                contractdata={contractData}
                userid={dataCopy._id}
                userinfo={dataCopy}
                className="contract-drawer"
                source="user"

            />) : ""
            }

            {/* ------------------------------ */}

            {/* -------- ContractViewModal ----- */}
            {contractPreviewModal ? <ContractViewModal
                toogle={contractPreviewModal}
                tooglefunc={setContractPreviewModal}
                contractdata={contractTemplateData}
                userinfo={dataCopy}
                source="user"

            /> : ""}
            {/* --------------------------------- */}


            {/* ------ followup ------ */}
            {followUpModal ? (<FollowUpcall
                open={followUpModal}
                close={closeModal}
                addedById={userInfo.userinfo._id}
                addedForId={dataCopy._id}
                listData={followUpCallList}
                loading={loaderForFollowUp}
            />) : ""}
            {/* ------------------------ */}


            {/* ---- Last login Modal details ---- */}
            {loginPopupData != null && loginPopupData.length > 0 ? (<LastLoginModal
                popupdata={loginPopupData}
                popuptoogle={loginPopupToogle}
                popuptooglefunc={setloginPopupToogle}

            />) : ""}
            {/* ------------------------- */}


            {/* ---- Email Send Drawer --- */}

            {dataCopy.unsubscribe_email !== 1 ? <EmailSendDrower open={openDrowerForEmail}
                close={closeDrower}
                data={emailtemplateData}
                addedById={userInfo.userinfo._id}
                addedForId={multiMail ? multiIdData : dataCopy._id}
                loading={loaderForsendmail}
                submistionstate={submistionstate} /> : ""}
            {/* --------------------------- */}


            {/* ----- StageLifecycleModal ----- */}
            {
                showstageList ? (<ShowStageListModal
                    open={showstageList}
                    toogle={showstageList}
                    tooglefunc={setshowstageList}
                    setModalResponse={setshowstageListAction}
                    stagesdata={fetchStagelistData}
                    UserName={dataCopy.fullname}
                />) : ''
            }
            {/* --------------------------------- */}


            {/* ---- tagLifecycleModal------ */}
            {showtagListModal ? (
                <ShowTagListModal
                    toogle={showtagListModal}
                    tooglefunc={setshowtagListModal}
                    setModalResponse={setStatusAction}
                    tagsdata={taglifecycledatas}
                    UserName={dataCopy.fullname}
                />
            ) : (
                ""
            )}



            {/* --------- SnackBar -------- */}
            {snackFire != false && resMessage !== null ? <SnackBarComp
                setSnackFire={setSnackFire}
                resMessage={resMessage}
                snackFire={snackFire}
                endpointResStatus={endpointResStatus}
            /> : ""}
            {/* ------------ */}




        </>
    )
}


export default UserManagementList;