import { TableContainer } from "@mui/material";
import React, { useRef,useEffect } from "react";
import ListingTable from "../../../listing/listing";
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
const FollowUpCalladded = ({ listData ,setSearchGridData,setsearchBtnClick}) => {

  let mindate = useSelector((state) =>
    state.DashboardReducer?.mindate ? state.DashboardReducer.mindate : {}
  );
  let maxDate = useSelector((state) =>
    state.DashboardReducer?.maxDate ? state.DashboardReducer.maxDate : {}
  );

  const searchForFollowUpCall = useSelector((state) =>state.formSlice?.formData?.searchForFollowUpCall ? state.formSlice.formData.searchForFollowUpCall : {} );
  let gridData = useSelector(state=>state.tableSlice?.otherResData?.FolltableDataDashboard?.grid_data ? state.tableSlice.otherResData.FolltableDataDashboard.grid_data: [])
  const searchFormData = useSelector((state) =>state.formSlice?.formSubmissionState?.searchForFollowUpCall ? state.formSlice.formSubmissionState.searchForFollowUpCall : {} );

  useEffect(() => {
    FolltableDataDashboard.current.searchData.formData.fields.forEach((data) => {
        data.defaultValue=searchFormData[data.name]?searchFormData[data.name]:undefined
        
        })
    // console.log("searchFormData", searchFormData);
  }, [JSON.stringify(searchFormData)]);

  useEffect(()=>{
    if(searchForFollowUpCall === 4){
      setsearchBtnClick(1)
    }
  },[searchForFollowUpCall])


  var modifyTableHeaders = [
    { val: "added_by_user_name", name: "Added By" },
    { val: "added_by_email", name: "Added By Email" },
    { val: "added_for_user_name", name: "Added For " },
    { val: "added_for_email", name: "Added For Email" },
    { val: "Added_for_phone", name: "Added For Phone" },
    { val: "added_by_phone", name: "Added By Phone" },
    { val: "notes", name: "Notes" },
  ];

  useEffect(()=>{
    setSearchGridData(gridData)
    console.log("gridData",gridData);
  },[JSON.stringify(gridData)])
  // tabil data
  let FolltableDataDashboard = useRef({
    tableId: "FolltableDataDashboard",
    tableTitle: "Follow Up Call List view",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "users/admin-dashboard-list-grid",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "users/admin-dashboard-list-grid",
    },
    reqBody: {
      "condition": {
        "limit": 5,
        "skip": 0
      },
      "sort": {
        "field": "_id",
        "type": "desc"
      },
      "searchcondition": {
        createdon_datetime: {
          $lte: maxDate,
        },
      },
      source: "follow_up_calls",
      type: "follow_up_call",
      "project": {},
      "token": "",
      "count": false
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },
    rowsPerPageOptions: [5],
    sortFields: ["added_by_user_name", "added_by_email", "added_for_user_name", "added_for_email"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },

    ],
    buttons: [
      {
        id: "summarize_icon",
        label: "Notes",
        type: "other",
        name: "summarize_icon",
        title: "Notes",
      },
      {
        id: "folder_icon",
        label: "Folder View",
        type: "other",
        name: "folder_icon",
        title: "Folder View",
      },
      {
        id: "assessment_icon",
        label: "Training Report",
        type: "other",
        name: "assessment_icon",
        title: "Training Report",
      },
      {
        id: "streetview_icon",
        label: "Leads",
        type: "other",
        name: "streetview_icon",
        title: "Leads",
      }
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },
    searchData: {
      heading: "SEARCH FOLLOW UP CALL",
      type: "drawer",
      formData: {
        id: 'searchForFollowUpCall',
        formWrapperClass: "formWrapperClass",
        formInlineCLass: "userForm",
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search Added By",
            name: "name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col2 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/follow-up-call",
            reqBody: {

              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "added_by",
              "source": "follow_up_calls"
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "added_by" },

          },

          {
            id: 2,
            label: "Search Added By Email ",
            name: "email",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col2 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/follow-up-call",
            reqBody: {
              // "formId": 'teamsearch',
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "added_by_email",
              "source": "follow_up_calls"
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "added_by" },

          },


          {
            id: 3,
            label: "Search Added For",
            name: "forname",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/follow-up-call",
            reqBody: {
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "added_for",
              "source": "follow_up_calls"
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "added_for" },

          },


          {
            id: 4,
            label: "Search Added For Email",
            name: "foremail",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "dashboard-cards/follow-up-call",
            reqBody: {
              "condition": {
                "limit": 5,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "email"
              },
              "searchcondition": {
                createdon_datetime: {
                  $lte: maxDate,
                },

              },
              "type": "added_for_email",
              "source": "follow_up_calls"
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "added_for" },

          },



        ]
      }
    },
  })





  return (
    <TableContainer component={Paper}>

      <ListingTable
        tableData={FolltableDataDashboard.current}
        dataset={listData}
        modifyHeaders={modifyTableHeaders}
      />

    </TableContainer>
  );
};

export default FollowUpCalladded;
