import React, { useRef, useState } from 'react'
import "./LandingPage4.css"
import { Form } from '../../../../form/Form';
import { useEffect } from 'react';
import store from '../../../../store';
import { setLeadinfo } from '../../../login/loginReducer';
import { fetchCalenderEvents } from '../../../Calendar/calenderReducer';
import { resolvefunction } from '../../../../helper/helperFunctions';
import StateArray from '../../../../assets/json/state';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { addconverstiondata, addlandingpagetwodatalead, clearlandingpagesubmittionstate, getlandingpagedata, getlandingpageoneuserdata, updateStages, updateTags } from '../LandingpageReducer';
import { setSnackbar } from '../../../Layout/layoutReducer';



export default function LandingPage4() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [locationArry, setLocationArry] = useState([]);
    // const [submissionState, setSubmissionState] = useState(0);
    const [loading, setloading] = useState(false);

    const formInputData = useSelector((state) =>
        state.formSlice.formData.userForm4 ? state.formSlice.formData.userForm4 : null
    );



    const submissionState = useSelector((state) =>
        state.formSlice.formSubmissionState.userForm4 ? state.formSlice.formSubmissionState.userForm4 : null
    );

    const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer?.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});

    const addedData = useSelector(state => (state.LandingpagefontReducer?.addedinfo && Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0) ? state.LandingpagefontReducer.addedinfo : {});
    const landingpageformsubmitionstate = useSelector(state => (state.LandingpagefontReducer?.landingpagesubmittionstate) ? state.LandingpagefontReducer.landingpagesubmittionstate : 0)
    const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
    const [conversionapicall, setConversionapicall] = useState(false);
    // console.log("loading", loading)
    const { uniquename } = useParams();

    let currTime = new Date().getTime();
    let dayAfterTomorrow = (currTime + (48 * 3600 * 1000));
    useEffect(() => {
        if (userData && ipinfo) {
            console.log("userData", userData);
            let body = {
                // "campaign_id":userData.campaign_id,
                "associated_program": userData.associated_program,
                "landing_page_id": userData.landing_page_id,
                "landing_page_slug": userData.landing_page_slug,
                // "campaign_added_by":userData.user_id,
                // "hiring_manager":userData.hm_id,
                "ipInfo": ipinfo
            }
            if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
            if (userData.user_id) body['campaign_added_by'] = userData.user_id;
            if (userData.hm_id) body['hiring_manager'] = userData.hm_id;

            console.log("+++++++++++++++++++", body);
            if (body && body.landing_page_id && !conversionapicall) {
                dispatch(addconverstiondata(body))
                setConversionapicall(true)

            }

            //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
            //    console.log("bodyc",bodyc);
        }

    }, [JSON.stringify(ipinfo), JSON.stringify(userData)])
    let reqbodyforjobcategory = {}
    if (typeof uniquename != 'undefined') {
        reqbodyforjobcategory = {
            "uniquename": uniquename,
        }
    }
    else {
        reqbodyforjobcategory = {
            "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG,
        }
    }

    useEffect(() => {
        if (uniquename) {

            store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
        }
        else {

            store.dispatch(getlandingpagedata(reqbodyforjobcategory));
        }
    }, [])
    const [slotListBody, setSlotListBody] = useState({
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "start_datetime_unix",
            "type": "desc"
        },
        "searchcondition": {
            // userid: getcookies.userInfo?._id,
            "start_datetime_unix": {
                "$gte": dayAfterTomorrow
            }
        }
        // "slot_creation_type": props.type === 'np' ? 2 : 1
    })

    useEffect(() => {
        let Newarr = [];
        if (StateArray && StateArray.length > 0) {
            for (let i in StateArray) {
                Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
            }
            setLocationArry(Newarr);
        }
    }, []);

    useEffect(() => {
        if (submissionState && submissionState === 4) {
            addapicall(formInputData);
        }
    }, [submissionState]);

    const addapicall = async (val) => {
        console.log("dataacccccccccccccccccccccccc", val);

        val = { ...val }
        let body = {
            "firstname": val.firstname,
            "lastname": val.lastname,
            "email": val.email,
            "phone": val.phone,
            "city": val.city,
            "state": val.state,
            "zip": val.zip,
            //   "address": val.address,
            "website": val.website,
            "EmployeeSize": val.EmployeeSize,
            "yearsInBusiness": val.yearsInBusiness,
            "campaign_id": userData.campaign_id,
            "associated_program": userData.associated_program,
            "page_id": userData.landing_page_id,
            "page_slug": userData.landing_page_slug,
            "user_id": userData.user_id,
            "hiring_manager": userData.hm_id,
            "createdon_datetime": new Date().getTime(),
            "status": 0,
            "rep_id": userData.rep_id,
            "aer_id": userData.aer_id,
            "associated_bdd": userData.associated_bdd,
            "added_type": 'landingpage',
            "calender_access_form_submit_time": userData.calendar_access ? 2 : 1

            // is_lead: true,


        }
        console.log("");

        if (Object.keys(body).length > 0) {
            console.log("body-----", body);

            setloading(true)
            await store.dispatch(addlandingpagetwodatalead(body));
            // setActive(false)
            setloading(false)
            // if(){

            // }
        }



    };

    useEffect(() => {
        console.log("userData", landingpageformsubmitionstate, addedData);

        //   resolvefunction(navigate, `/calendar/${addedData._id}`, [
        //     fetchCalenderEvents(slotListBody),
        //   ]);
        dispatch(setLeadinfo(addedData));
        store.dispatch(clearlandingpagesubmittionstate())


        const data = {
            stage_slug: '5QH6nS8FXNawY',
            added_by: addedData._id,
            added_for: addedData._id,
            is_delete: 0,
            status: 1
        }
        console.log("data===========================>", data)
        // return
        dispatch(updateStages(data));


        const tagdata = {
            added_by: addedData._id,
            added_for: addedData._id,
            key: "Wnv14tRS9FlmV",
            label: "hm-policiesandcompliance-signed-success",
            is_delete: 0,
            status: 1
        }
        console.log("tagdata===========================>", tagdata)
        // return
        dispatch(updateTags(tagdata));

        if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
            dispatch(setSnackbar({ open: true, message: "Thank you for Submitting !", status: 'success' }))
            if (userData.calendar_access && userData.calendar_access == 1) {
                if (userData?.associated_bdd && userData.associated_bdd[0]) {
                    slotListBody.searchcondition['userid'] = userData.associated_bdd[0]
                    slotListBody['organiser_role'] = 'bdd'
                }
                slotListBody['_id'] = addedData._id;
                resolvefunction(navigate, `/calendar/${addedData._id}/bdd/${userData.associated_bdd[0]}`, [
                    fetchCalenderEvents(slotListBody),
                ]);
            }
            else {
                navigate(`/thankyou`);
            }


        }
        //  return () => {
        //   store.dispatch(clearlandingpagesubmittionstate())
        // //   store.dispatch(resetresolvenew('/:uniquename'))
        // }

    }, [landingpageformsubmitionstate])


    // useEffect(() => {
    //     if (submissionState && submissionState === 4) {
    //         addapicall(formInputData);
    //     }
    // }, [submissionState]);

    // const addapicall = async (dataccc) => {
    //     let data = dataccc;
    //     console.log("websiteInformation data", data);
    //     setloading(true);
    //     // formSubmission(true)

    //     if (data != null) {
    //         // data.website_information']=websiteInformation
    //         const requestOptions = {
    //             method: "POST",
    //             headers: { "Content-type": "application/json" },
    //             body: JSON.stringify(data),
    //         };
    //         const response = await fetch(
    //             process.env.REACT_APP_API_URL + "lead-manage/addorupdatelead",
    //             requestOptions
    //         );
    //         // console.log("++++response+++++++",response);

    //         const respdata = await response.json();
    //         console.log("++++cv+++++++", respdata);

    //         if (respdata && respdata.status === "success") {

    //             resolvefunction(navigate, `/calendar/${respdata.results.res._id}`, [
    //                 fetchCalenderEvents(slotListBody),
    //             ]);
    //             dispatch(setLeadinfo(respdata.results.res))
    //             // setloading(false);
    //         } else {
    //             // props.formSubmission(false);
    //             setloading(false);
    //         }
    //     }
    // };

    // const formDataL2 = {
    //     id: 'userForm4',
    //     formtype: "add",
    //     api_url: process.env.REACT_APP_API_URL,
    //     // endPoint: "lead-manage/add-update-intake-form",
    //     // endPointBodyWrapper: "data",
    //     // urlPathOnSuccessfulFormSubmission: "/calendar",
    //     msgOnFailedFormSubmission: "Something Went Wrong",
    //     formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    //     formInlineCLass: "userForm", // Can be undefined default will be formInline
    //     formButtonClass: "l2submitbtnsection",
    //     submitBtnName: "Submit",
    //     // submitBtnStartIcon: 'image_search_icon',
    //     // submitBtnEndIcon: 'search_icon',
    //     resetBtnName: "Reset",
    //     formAdditionalSubmissionData: {
    //         type: "admin",
    //         status: "active",
    //     },
    //     fields: [
    //         //////////////////////////////////// Text Input ///////////////////////////////
    //         {
    //             id: 0,
    //             // heading: "Full Name",
    //             label: "First Name",
    //             name: "firstname",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //         },
    //         {
    //             id: 1,
    //             // heading: "Full Name",
    //             label: "Last Name",
    //             name: "lastname",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },
    //         },
    //         {
    //             id: 2,
    //             // heading: "Full Name",
    //             label: "Email",
    //             name: "email",
    //             className: 'formfild',
    //             type: "text",
    //             inputType: "email",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },

    //         {
    //             id: 4,
    //             // heading: "Full Name",
    //             label: "Website",
    //             name: "website",
    //             className: 'formfild',
    //             type: "text",
    //             inputType: "website",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },

    //         {
    //           id: 3,
    //           // heading: "Full Name",
    //           label: "Phone",
    //           name: "phone",
    //           className: 'formfild',
    //           type: "text",
    //           inputType: "phone",
    //           rules: { required: true, minLength: 10 },
    //           errorMessage: {
    //               required: "This Field is Required",
    //               custom: "Value is Invalid"
    //           },

    //         },



    //         // {
    //         //     id: 4,
    //         //     heading: "Full Name",
    //         //     label: "Address",
    //         //     name: "address",
    //         //     className: 'formfild',
    //         //     type: "text",
    //         //     inputType: "text",/////////////// If not mentioned default will be text //////////////
    //         //     rules: { required: true },
    //         //     errorMessage: {
    //         //         required: "This Field is Required",
    //         //         custom: "Value is Invalid"
    //         //     },
    //         // },


    //         {
    //           id: 6,
    //           // heading: "Full Name",
    //           label: "State",
    //           name: "state",
    //           className: 'formfild state',
    //           type: "select",
    //           // other: true,
    //           values: locationArry,
    //           rules: { required: true },
    //           errorMessage: {
    //               required: "This Field is Required",
    //               custom: "Value is Invalid"
    //           },
    //           defaultValue: "",
    //       },

    //         {
    //             id: 5,
    //             // heading: "Full Name",
    //             label: "City",
    //             name: "city",
    //             className: 'formfild',
    //             type: "text",
    //             rules: { required: true },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid"
    //             },

    //         },

    //         {
    //             id: 7,
    //             // heading: "Full Name",
    //             label: "Zip",
    //             name: "zip",
    //             className: 'formfild',
    //             type: "text",
    //             inputType: "text",
    //             rules: { required: true, maxLength: 5, minLength: 5 },
    //             errorMessage: {
    //                 required: "This Field is Required",
    //                 custom: "Value is Invalid",
    //                 maxLength: "Maxlength length 5 allowed",
    //                 minLength: "Minlength length 5 allowed",
    //             },

    //         },

    //         {
    //           id: 8,
    //           // heading: "Full Name",
    //           label: "Employee Size",
    //           name: "EmployeeSize",
    //           className: 'formfild',
    //           type: "text",
    //           // inputType: "number",
    //           rules: { required: true },
    //           errorMessage: {
    //             required: "This Field is Required",
    //             maxLength: "MaxLength length 20 allowed",
    //             validate: "Lastnameeeeeeee is not valid",
    //             custom: "Value is Invalid"
    //           },

    //         },

    //       {
    //         id: 9,
    //         // heading: "Full Name",
    //         label: "Years in Business",
    //         name: "years in business",
    //         className: 'formfild',
    //         type: "text",
    //         rules: { required: true },
    //         errorMessage: {
    //             required: "This Field is Required",
    //             custom: "Value is Invalid"
    //         },

    //     },




    //     ]

    // };

    const formDataL4 = {
        id: 'userForm4',
        formtype: "add",
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "lead-manage/add-update-intake-form",
        // endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/calendar",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "l2submitbtnsection",
        submitBtnName: "Submit",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            type: "admin",
            status: "active",
        },
        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                // heading: "Full Name",
                label: "First Name",
                name: "firstname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 1,
                // heading: "Full Name",
                label: "Last Name",
                name: "lastname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 2,
                // heading: "Full Name",
                label: "Email",
                name: "email",
                className: 'formfild',
                type: "text",
                inputType: "email",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },

            {
                id: 4,
                // heading: "Full Name",
                label: "Website",
                name: "website",
                className: 'formfild',
                type: "text",
                inputType: "website",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },

            {
                id: 3,
                // heading: "Full Name",
                label: "Phone",
                name: "phone",
                className: 'formfild',
                type: "text",
                inputType: "phone",
                rules: { required: true, minLength: 10 },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },



            // {
            //     id: 4,
            //     heading: "Full Name",
            //     label: "Address",
            //     name: "address",
            //     className: 'formfild',
            //     type: "text",
            //     inputType: "text",/////////////// If not mentioned default will be text //////////////
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },
            // },


            {
                id: 6,
                // heading: "Full Name",
                label: "State",
                name: "state",
                className: 'formfild state',
                type: "select",
                // other: true,
                values: locationArry,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: "",
            },

            {
                id: 5,
                // heading: "Full Name",
                label: "City",
                name: "city",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },

            {
                id: 7,
                // heading: "Full Name",
                label: "Zip",
                name: "zip",
                className: 'formfild',
                type: "text",
                inputType: "text",
                rules: { required: true, maxLength: 5, minLength: 5 },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                    maxLength: "Maxlength length 5 allowed",
                    minLength: "Minlength length 5 allowed",
                },

            },

            {
                id: 8,
                // heading: "Full Name",
                label: "Employee Size",
                name: "EmployeeSize",
                className: 'formfild',
                type: "text",
                // inputType: "number",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },

            {
                id: 9,
                // heading: "Full Name",
                label: "Years in Business",
                name: "yearsInBusiness",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },




        ]

    };

    // store.subscribe(() => {
    //     const storeData = store.getState();
    //     if (storeData && storeData.formSlice && storeData.formSlice.formSubmissionState && storeData.formSlice.formSubmissionState.userForm4) {
    //         setSubmissionState(storeData.formSlice.formSubmissionState.userForm4);
    //     }
    //     // console.log(setSubmissionState, "setSubmissionState")
    //     // console.log("landing store", storeData)
    // })

    // const goToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //     });
    // };
    const goToTop = () => {
        const element = document.getElementById('sectionForm');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='commercialBody'>
            <div className='new_commercial_banner_BG'>
                <div className='new_commercial_banner_img1'>
                    <div className='new_commercial_wrapper'>
                        <div className='new_commercial_banner_left'>
                            <div className='new_commercial_banner_left_con'>
                                <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_logo.webp' className='commercialLogo' />
                                <h2>ASPIRE</h2>
                                <h3>COMMERCIAL PAYMENTS <span>(ACP)</span></h3>
                                <h4>Aspire Commercial Payments strives building the
                                    world’s best B2B integrated payments platform.This is an secure automated process which optimizes all your
                                    payments so that you can stay focused on building the
                                    best version of your company.</h4>
                                <div className='new_commercial_banner_left_blocktext'>
                                    <div className='new_commercial_left_blocktextcon'>
                                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/new_commrcial_banner_textlogo1.webp' />
                                        <h5>SECURITY AND<br /> AUTOMATION</h5>
                                        <p>Fast, Efficient, Secure,
                                            On time, These are the attributes of our B2B payments platform.</p>

                                    </div>

                                    <div className='new_commercial_left_blocktextcon'>
                                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/new_commrcial_banner_textlogo2.webp' />
                                        <h5>EFFICIENCY<br />
                                            AND SPEED</h5>
                                        <p>Increase productivity and reduce time spent processing payments.</p>

                                    </div>


                                    <div className='new_commercial_left_blocktextcon'>
                                        <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/new_commrcial_banner_textlogo3.webp' />
                                        <h5>REVENUE AND<br />
                                            COST SAVINGS</h5>
                                        <p>Earn revenue on digital payments and reduce payment processing costs.</p>

                                    </div>

                                </div>


                            </div>
                            <div className='new_commercial_banner_arrow_text'>
                                <label>Please fill out the form and schedule to talk with our experts!</label>
                            </div>

                        </div>
                        <div className='new_commercial_banner_right' id='sectionForm'>
                            <div className='new_l4form_style'>
                                <div className='new_l4form_box'>
                                    <div className='new_l4form_style_heading'>
                                        <h2>SPEAK WITH AN ASPIRE  COMMERCIAL PAYMENTS EXPERT RIGHT AWAY</h2>
                                    </div>

                                    <Form formData={formDataL4} />

                                    {loading ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>) : (null)}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>



            {/* <div className='commercial_banner_BG'>
                <div className='commercial_wrapper'>
                    <div className='commercial_banner_left'>
                        <div className='commercial_banner_left_con'>
                            <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_logo.webp' className='commercialLogo' />
                            <h2>CORPORATE SPENDING</h2>
                            <h3><strong>INNOVATIONS</strong> <span>(CSI)</span></h3>
                            <h4>Corporate Spending Innovations <span>(CSI)</span> strives building the world’s best B2B integrated payments platform.This is an secure automated process which optimizes all your payments so that you can stay focused on building the best
                                version of your company.</h4>

                            <div className='commercial_banner_left_blocktext'>

                                <div className='commercial_left_blocktextcon'>
                                    <h5>SECURITY AND<br /> AUTOMATION</h5>
                                    <p>Fast, Efficient, Secure,
                                        On time, These are the attributes of our B2B payments platform.</p>

                                </div>

                                <div className='commercial_left_blocktextcon'>
                                    <h5>EFFICIENCY<br />
                                        AND SPEED</h5>
                                    <p>Increase productivity and reduce time spent processing payments.</p>

                                </div>


                                <div className='commercial_left_blocktextcon'>
                                    <h5>REVENUE AND<br />
                                        COST SAVINGS</h5>
                                    <p>Earn revenue on digital payments and reduce payment processing costs.</p>

                                </div>




                            </div>
                        </div>
                        <div className='commercial_banner_arrow_text'>
                            <label>Please fill out the form and schedule to talk with our experts!</label>
                        </div>

                    </div>

                    <div className='commercial_banner_right landingFour'>
                        <div className='l4form_style'>
                            <div className='l4form_box'>
                                <div className='l4form_style_heading'>
                                    <h2>SPEAK WITH AN ASPIRE  COMMERCIAL PAYMENTS EXPERT RIGHT AWAY</h2>
                                </div>


                                <Form formData={formDataL2} />
                  {loading  ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>) : (null)}

                            </div>
                        </div>
                    </div>
                </div>

            </div> */}

            <div className='commercial_block2'>
                <div className='commercial_wrapper'>
                    <div className='commercial_block2_con1'>
                        <div className='commercial_block2_con1_bg'>

                            <div className='commercial_block2_con1Div1'>


                                <div className='commercial_block2_con1Div1H1'>

                                    <h1>POWERFUL AUTOMATED PAYMENTS FOR YOUR BUSINESS</h1>
                                </div>

                                <p>One of the crucial party of day-to-day business operations is receiving invoices and making payments. It can take a considerable amount of time trying to manage this process manually. And time equals money. However, the actions that you take today to automate payments will deliver immediate results. This is where CSI comes in. We are a processor of all payment types including cards, ACH, and check, Issuer of both Mastercard and Visa.</p>



                            </div>

                        </div>
                    </div>

                    <div className='commercial_block2_con2'>



                        <div className='commercial_block2_con2_con'>
                            <div className='commercial_block2_con2_con_wrapper'>
                                <div className='commercial2_arrow_block'>
                                    <span className='commercial2_arrow1'></span>
                                    <span className='commercial2_arrow2'></span>
                                    <span className='commercial2_arrow3'></span>
                                    <span className='commercial2_arrow4'></span>
                                    <span className='commercial2_arrow5'></span>
                                </div>

                                <div className='commercial_block2_con2_con_body'>
                                    <div className='commercial_block2_con2_conTop'>
                                        <img src='https://all-frontend-assets.s3.amazonaws.com/COMMERCIAL_block2_block1_icon1.webp'></img>
                                        <h1>EFFICIENCY</h1>
                                    </div>
                                    <div className='commercial_block2_con2_conBottom'>
                                        <h2>Automate 100% of your payments including virtual credit card, ACH, B2B Payments Network and check.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='commercial_block2_con2_con'>
                            <div className='commercial_block2_con2_con_wrapper'>

                                <div className='commercial2_arrow_block'>
                                    <span className='commercial2_arrow1'></span>
                                    <span className='commercial2_arrow2'></span>
                                    <span className='commercial2_arrow3'></span>
                                    <span className='commercial2_arrow4'></span>
                                    <span className='commercial2_arrow5'></span>
                                </div>
                                <div className='commercial_block2_con2_con_body'>
                                    <div className='commercial_block2_con2_conTop'>
                                        <img src='https://all-frontend-assets.s3.amazonaws.com/COMMERCIAL_block2_block1_icon2.webp'></img>
                                        <h1>CONTROL</h1>
                                    </div>
                                    <div className='commercial_block2_con2_conBottom'>
                                        <h2>Our solution supports internal controls to minimize risk and fraud.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='commercial_block2_con2_con'>
                            <div className='commercial_block2_con2_con_wrapper'>

                                <div className='commercial2_arrow_block'>
                                    <span className='commercial2_arrow1'></span>
                                    <span className='commercial2_arrow2'></span>
                                    <span className='commercial2_arrow3'></span>
                                    <span className='commercial2_arrow4'></span>
                                    <span className='commercial2_arrow5'></span>
                                </div>


                                <div className='commercial_block2_con2_con_body'>
                                    <div className='commercial_block2_con2_conTop'>
                                        <img src='https://all-frontend-assets.s3.amazonaws.com/COMMERCIAL_block2_block1_icon3.webp'></img>
                                        <h1>FLEXIBILITY</h1>
                                    </div>
                                    <div className='commercial_block2_con2_conBottom'>
                                        <h2>Our cloud-based platform enables you to manage payments from any location, any time</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='commercial_block2_con2_con'>
                            <div className='commercial_block2_con2_con_wrapper'>
                                <div className='commercial2_arrow_block'>
                                    <span className='commercial2_arrow1'></span>
                                    <span className='commercial2_arrow2'></span>
                                    <span className='commercial2_arrow3'></span>
                                    <span className='commercial2_arrow4'></span>
                                    <span className='commercial2_arrow5'></span>
                                </div>
                                <div className='commercial_block2_con2_con_body'>
                                    <div className='commercial_block2_con2_conTop'>
                                        <img src='https://all-frontend-assets.s3.amazonaws.com/COMMERCIAL_block2_block1_icon4.webp'></img>
                                        <h1>GROWTH</h1>
                                    </div>
                                    <div className='commercial_block2_con2_conBottom'>
                                        <h2>Earn rebates on virtual card and network payments.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>

            <div className='commercial_block3'>

                <div className='commercial_wrapper'>
                    <div className='commercial_block3_body'>

                        <div className='commercial_block3_left'>

                            <h2>Future-proof your payments.</h2>
                            <p>As a business you tailor your products and services to meet the evolving needs of your clients. <span>CSI does the same for you</span>. Our payment platform is future-proof. As your business
                                evolves, so do our products.</p>
                        </div>
                        <div className='commercial_block3_right'>
                            <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_block3_img.webp'></img>
                        </div>


                    </div>


                </div>

            </div>

            <div className='commercial_block4'>

                <div className='commercial_wrapper'>

                    <div className='commercial_block4_con1'>


                        <h2>AP automation that CSI Does</h2>
                        <ul>
                            <li>Automize and digitize all your payments.</li>
                            <li>Leverage our processing platform for card issuing and digital payments.</li>
                            <li>Embed our payments solution into your <span>ERP</span>.</li>
                            <li>Strengthen your brand through a white label solution.</li>
                        </ul>

                        <h3><p>The CSI Battlecard and the corporate team take those who might be paying bills in a number of different ways, maybe not using virtual cards and all purchases are primary leading or headline products. A virtual credit card is a card that is an email, one time use card. <span>Anyone can pay instead of calling and reading a card number over the phone</span>. Digital virtual cards are safer and faster, environmentally more secure
                            and pays are rebated back to the vendor. </p></h3>

                        <button className='commercial_block4BTN1' onClick={goToTop}>Get Started Now</button>


                    </div>
                    <div className='commercial_block4_con2'>
                        <h2>Industries currently using this service:</h2>

                        <ul>
                            <li>Media</li>
                            <li>Hospitality</li>
                            <li>Golf club</li>
                            <li>Telecom</li>

                            <li>Nonprofit</li>
                            <li>Healthcare</li>
                            <li>Property management</li>
                            <li>Manufacturing</li>

                            <li>E commerce</li>
                            <li>Business services</li>
                            <li>Oil, gas, energy</li>



                        </ul>

                        <h3>Schools, colleges, universities and some construction companies use virtual cards. <span>Clients get 1% rebate, that’s a $100000,</span> essentially free money which is generated paying some of his
                            bill in a different manner.</h3>


                    </div>


                </div>
            </div>

            <div className='commercial_block5'>

                <div className='commercial_wrapper'>
                    <div className='commercial_block5_con'>
                        <div className='commercial_block5_conBG'>
                            <div className='commercial_block5_con_text'>

                                <div className='commercial_block5_con_text_BG'><h2>Client profile that aren’t a good fit:</h2></div>

                                <ul>
                                    <li>Prospect looking for check only provider.</li>

                                    <li>Prospects that are manually <span>paying international wires with little U.S. payments.</span></li>
                                    <li>Prospects that have more customer/employee reimbursement payments vs supplier payments.</li>
                                </ul>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div className='commercial_block6'>

                <div className='commercial_wrapper'>
                    <div className='commercial_block6_conBG'>
                        <div className='commercial_block6_con'>
                            <h2>Our Solutions</h2>
                            <h3>You deserve a payments automation solution that provides
                                a great return on investment and we make that happen.</h3>

                            <div className='commercial_block6_text1'>
                                <div className='commercial_block6_text1_sub'>
                                    <h1>Turn Payables into Profit.</h1>
                                    <p>Is your accounts payable still issuing paper checks and spending countless hours on reconciliation? The time to automate is now.</p>

                                </div>
                            </div>

                            <ul>
                                <li> Gain efficiencies and cost saving benefits of automation.</li>
                                <li> Securely manage your payments from any location at any time.</li>
                                <li> Earn rebates on virtual card and network payments.</li>
                            </ul>
                            <button className='commercial_block6BTN' onClick={goToTop}>Schedule an appointment now!</button>

                        </div>
                    </div>
                </div>
            </div>

            <div className='commercial_block7'>

                <div className='commercial_wrapper'>
                    <div className='commercial_block7_bg'>
                        <div className='commercial_block7_con'>

                            <h2>Accounts Payable <br />
                                Automation <span>(AP Automation)</span></h2>

                            <h3>Transform your Accounts Payable (AP) department
                                into an automated powerhouse. Smart, secure and streamlined payments convert your AP department from a cost center
                                to a revenue generator.</h3>


                            <div className='commercial_block7Text'>

                                <div className='commercial_block7Text_sub'>
                                    <h4>Overview:</h4>
                                    <p>Make payments the way you and your suppliers want them : <span>easy</span>. Manual processes slow down revenue growth. Payment automation allows your team to simplify reconciliations, accelerate the payments flow and earn
                                        rebates on qualifying payments.</p>
                                    <h4>Payment Types:</h4>
                                    <p>Each supplier has unique payment requirements. CSI’s platform can deliver on all of them. ACH, B2B Payments Network, Foreign exchange (FX), Check, If it’s a payment method, we handle it.</p>

                                </div>

                            </div>
                            <button className='commercial_block7BTN' onClick={goToTop}>Schedule an appointment now!</button>


                        </div>
                    </div>

                </div>
            </div>

            <div className='commercial_block8'>

                <div className='commercial_wrapper'>
                    <div className='commercial_block8_bg'>
                        <div className='commercial_block8_con'>

                            <div className='commercial_block8_div'>
                                <div className='commercial_block8_divSub'>

                                    <div className='commercial_block8_icon'><img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_block8Icon1.webp'></img></div>
                                    <h2>Virtual Cards</h2>
                                    <div className='commercial_block8_text'>Secure, single use, electronic credit cards.</div>
                                </div>
                            </div>

                            <div className='commercial_block8_div'>
                                <div className='commercial_block8_divSub'>

                                    <div className='commercial_block8_icon'><img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_block8Icon2.webp'></img></div>
                                    <h2>ACH</h2>
                                    <div className='commercial_block8_text'>Virtual Cards are not accepted? Use Automated Clearing House transfers to make fast and secure payments.</div>
                                </div>
                            </div>


                            <div className='commercial_block8_div'>
                                <div className='commercial_block8_divSub'>

                                    <div className='commercial_block8_icon'><img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_block8Icon3.webp'></img></div>
                                    <h2>B2B Payments<br />
                                        Network</h2>
                                    <div className='commercial_block8_text'>We took ACH and
                                        made it better. See invoice-level details for each transaction through
                                        our payments platform.</div>
                                </div>
                            </div>

                            <div className='commercial_block8_div'>
                                <div className='commercial_block8_divSub'>

                                    <div className='commercial_block8_icon'><img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_block8Icon4.webp'></img></div>
                                    <h2>Ghost Cards</h2>
                                    <div className='commercial_block8_text'>A virtual card type
                                        used for recurring transactions to the
                                        same supplier or multiple transactions across various suppliers.</div>
                                </div>
                            </div>

                            <div className='commercial_block8_div'>
                                <div className='commercial_block8_divSub'>

                                    <div className='commercial_block8_icon'><img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_block8Icon5.webp'></img></div>
                                    <h2>Foreign Exchange</h2>
                                    <div className='commercial_block8_text'>Payments in 18 countries and 140 currencies.</div>
                                </div>
                            </div>

                            <div className='commercial_block8_div'>
                                <div className='commercial_block8_divSub'>

                                    <div className='commercial_block8_icon'><img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage4/COMMERCIAL_block8Icon6.webp'></img></div>
                                    <h2>Check</h2>
                                    <div className='commercial_block8_text'>Your suppliers require check payments? Not a problem. Aspire Commercial payments will seamlessly process those payments for you.</div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

            <div className='commercial_block9'>

                <div className='commercial_wrapper'>
                    <div className='commercial_block9_bg'>

                        <div className='commercial_block9Heading'><h2>How it works:</h2></div>
                        <h3>Simple, secure, and streamlined business payments</h3>

                        <div className='commercial_block9Body'>

                            <div className='commercial_block9Body_sub'>
                                <div className='commercial_block9Body_subCon'>
                                    <div className='commercial_block9Body_subBG'>
                                        <label><div><span>1</span></div></label>
                                        <div className='commercial_block9BodyTextCon'>
                                            <h4>Simple</h4>
                                            <h5>Simple integration with any ERP. We make it easy to get your payments data into our platform. Upload a single native file from your ERP into our payments platform or directly integrate using our APIs or FTP services for automated data transfers.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='commercial_block9Body_sub'>
                                <div className='commercial_block9Body_subCon'>
                                    <div className='commercial_block9Body_subBG'>
                                        <label><div><span>2</span></div></label>
                                        <div className='commercial_block9BodyTextCon'>
                                            <h4>Supplier Enrollment
                                                & Enablement</h4>
                                            <h5>Ready to get your
                                                suppliers onboarded?
                                                We’ll launch a supplier
                                                enrollment campaign on your behalf to ensure
                                                easy disbursement of virtual card, ACH,
                                                check, network, or
                                                foreign exchange (FX)
                                                payments. We work with you and your suppliers to optimize your. </h5>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='commercial_block9Body_sub'>
                                <div className='commercial_block9Body_subCon'>
                                    <div className='commercial_block9Body_subBG'>
                                        <label><div><span>3</span></div></label>
                                        <div className='commercial_block9BodyTextCon'>
                                            <h4>Flexible</h4>
                                            <h5>Built for your unique processes. Set your internal controls, manage workflows, establish approval parameters, define reconciliation operations, and gain full visibility into all of your payments. </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='commercial_block9Body_sub'>
                                <div className='commercial_block9Body_subCon'>
                                    <div className='commercial_block9Body_subBG'>
                                        <label><div><span>4</span></div></label>
                                        <div className='commercial_block9BodyTextCon'>
                                            <h4>Income Opportunity</h4>
                                            <h5>Schedule your first “payment run” and start sending payments. See your Accounts Payable shift from a cost center to a revenue generator as you earn rebates on all virtual card and proprietary network payments.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <button className='commercial_block9BTN' onClick={goToTop}>Schedule an appointment now!</button>

                    </div>
                </div>
            </div>


        </div>
    )
}
