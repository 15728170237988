import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import ArticleIcon from '@mui/icons-material/Article';
import SendIcon from '@mui/icons-material/Send';
import { fetchContract } from '../userList/userListReducer';
import ContractViewModal from '../userList/contractViewModal';
import ContactDrawer from '../userList/sendcontractDrawer';
import { fetchContractTemplate } from '../userList/userListReducer';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from './FDSearch';
import { UserActivity } from './folderViewReducer';


const ContractManage = (props) => {
    const [contractPreviewModal, setContractPreviewModal] = useState(false);
    const [contractDrawer, setContractDrawer] = useState(false);

    const [dataCopy, setDataCopy] = useState({});  //--- dataCopy  ----//

    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

    // ---- States here -----
    const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const
    const contractTemplateData = useSelector(state => (state.userManagment?.contractTemplate) ? state.userManagment.contractTemplate : {});
    // const dataRef = useSelector(state => (state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.userslist1) ? state.tableSlice?.tempRowData?.userslist1 : {});
    const contractData = useSelector(state => (state.userManagment?.fetchContractData) ? state.userManagment.fetchContractData : [])
    const contractLoader = useSelector(state => (state.userManagment?.contractLoader) ? state.userManagment.contractLoader : false)
    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [JSON.stringify(paramId)])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    console.warn("<<<<< cookieData from User Contractmanage >>>>>", cookieData)
    console.warn("<<<<< queryParamId from User Contractmanage >>>>>", queryParamId)


    // --- loader --- 
    const loader = useSelector(state => (state.userFolderViewReducer?.loading
    ) ? state.userFolderViewReducer.loading
        : null);

    console.warn("<<<<<<<<<< contractlogs goes here >>>>>>>>>", props.contractlogs)

    const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
    const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})


   



    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };


    useEffect(() => {

        if (queryParamId != undefined && queryParamId != null) {

        }
    }, [])

    useEffect(() => {
        console.log("contractData", contractData);
        console.log("contractTemplateData", contractTemplateData);
    }, [contractData, contractTemplateData])


    useEffect(() => {
        if (contractTemplateData && Object.keys(contractTemplateData).length > 0 && contractTemplateData.status !== "error") {
            setContractPreviewModal(true)
        }
    }, [contractTemplateData])

    const HandelContractSend = () => {
        // setDataCopy(dataRef)
        // setContractPreviewModal(false)
        const data = {
            "condition": {},
            "sort": {
                "field": "name",
                "type": "asc"
            },
            "searchcondition": {
                "status": 1,
                "rep_only": 1
            },
            "project": { "name": 1 },
            "token": "",
            "data": { "user_id": props.queryParamId }
        }

        dispatch(fetchContract(data))
    }





    useEffect(() => {
        if (contractData.length > 1) {
            setContractPreviewModal(false)
            setContractDrawer(true)

        }
        else if (contractData.length == 1) {
            setContractDrawer(false)
            setContractPreviewModal(false)

            // console.warn("<<<<<<<<< contractData goes here >>>>>>>>>", contractData)

            const payload = {
                "searchcondition": {
                    "user_id": props.queryParamId,
                    "contract_id": contractData[0]._id
                }
            }
            dispatch(fetchContractTemplate(payload))

        }

    }, [contractData])

    const searchFields=[
        {
            id: 8,
            label: "Search by Start Date",
            name: "start_date",
            className: 'inputBlock inputBlock3line',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            dateFormat: 'dd/MM/yyyy',
           
    
          },
          {
            id: 9,
            label: "Search by End Date",
            name: "end_date",
            className: 'datePicker',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            dateFormat: 'dd/MM/yyyy',
            className: 'inputBlock inputBlock3line',
            errorMessage: {
              dateCheck: "End Date can not be less than Start Date",
            },
          },
    ]

    useEffect(() => {
        if (submissionState === 4) {
            const payload = {
                "condition": {
                  "limit": 5,
                  "skip": 0
                },
                "sort": {
                  "field": "activity_time",
                  "type": "desc"
                },
                "searchcondition": {
                  "user_id": queryParamId ? queryParamId : undefined,
                  "activity_time":`$or:[$gte:${searchData?.start_date?searchData.start_date:undefined},$lte:${searchData?.end_date?searchData.end_date:undefined}]`
              
        
                },
                "project": {},
                "token": "",
                "email": ""
              }
          dispatch(UserActivity(payload))
        }
      }, [submissionState])
      // ----------------

    return (
        <>


            <div>
                <h3> <ArticleIcon /> Contract Logs

                    <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>

                        <Tooltip title="Send Contract">
                            <IconButton onClick={() => HandelContractSend()} >
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Search Email">
                            <IconButton>
                                <SearchIcon onClick={() => setSearchDraw(true)}/>
                            </IconButton>
                        </Tooltip>
                    </div>

                </h3>
                {(loader || contractLoader) ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


                {props.contractlogs?.length > 0 ? props?.contractlogs?.map((x) =>
                    <div className='user-activity'>

                        <ul className='ua-data-block'>
                            <li>Contract:  {x.contract_name} </li>

                            <li>Sent On:  {format(x?.createdon_datetime, 'dd-MM-yyyy HH:mm:ss')} </li>
                            <li>Sent By: {x?.send_by} On {format(x?.createdon_datetime, 'dd-MM-yyyy HH:mm:ss')} </li>
                            {/* <li>View Contract: <a href={x.link} target="_blank"> {x?.link} </a>  </li> */}

                            <li>
                                <a href={x.link} target="_blank" className='actionBtn'><Button variant="contained" > VIEW CONTRACT</Button> </a>

                                {x.signed ? <a href={x.link} target="_blank" className='actionBtn'><Button variant="contained" > DOWNLOAD </Button> </a> : ""}

                            </li>
                        </ul>

                    </div>) : <p> No activity found!</p>}

            </div>
            {contractDrawer ? (<ContactDrawer
                open={contractDrawer}
                setcontractdrawer={setContractDrawer}
                contractdata={contractData}
                userinfo={props.folderdata}
                userid={props.queryParamId}
                className="contract-drawer" />)
                : ""
            }

            {contractPreviewModal ? <ContractViewModal
                toogle={contractPreviewModal}
                tooglefunc={setContractPreviewModal}
                contractdata={contractTemplateData}
                userinfo={props.folderdata}
            /> : ""}

            {searchDraw ? <FDSearch
                open={searchDraw}
                close={setSearchDraw}
                formFields={searchFields}
                searchFields={searchFields}
                type="contract_manage " /> : ""}


        </>
    )
}

export default ContractManage;






