import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { resolvefunction } from '../../helper/helperFunctions';
import { getroledata } from '../Backend/Role/RoleReducer';
import { MdDashboard, MdMiscellaneousServices } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { MdManageAccounts } from "react-icons/md";
import { MdLocalLibrary, MdBookmarks } from "react-icons/md";

import { getLandingListData } from '../Backend/LandingPage/LandingPageReducer';
import { getleaddata } from '../Backend/LeadManagement/LeadReducer';
import { getCampaignListData } from '../CampaignManagement/CampaignReducer';
import AdminMenu from './AdminMenu';

export default function BackendLeft() {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
  };
  const [isHidedropdown, setHidedropdown] = useState(false);
  const hidedropdown = (event) => {
    setHidedropdown((current) => !current);
    setIsActive(false);
  };
  let reqbodyforRole = {
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const { id } = useParams();

  return (
    <div>
      <div className='left_logo'><img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/logo_icon.webp'></img></div>
      <div className={`left_centerblock ${isActive ? "showdiv" : ""}`}>
        {/* <ul>
          <li>
            <button onClick={() => { resolvefunction(navigate, '/dashboard') }} className={(pathname && pathname == '/dashboard') ? 'active' : ''}>
              <MdDashboard></MdDashboard>

              <span>Dashboard</span>
            </button>
          </li>

          <li>
            <button onClick={() => { resolvefunction(navigate, '/usermanagement') }} className={(pathname && pathname == '/usermanagement') ? 'active' : ''}>
              <MdPerson></MdPerson>
              <span>Users</span>
            </button>
          </li>


          <li>
            <button onClick={() => { resolvefunction(navigate, '/role-list', getroledata(reqbodyforRole)) }} className={(pathname && pathname == '/role-list') ? 'active' : ''}>

              <MdManageAccounts></MdManageAccounts>

              <span>Role Management</span>
            </button>
          </li>
          <li>
            <button onClick={() => { resolvefunction(navigate, '/list-landingpage', getLandingListData(reqbodyforRole)) }} className={(pathname && pathname == '/list-landingpage') ? 'active' : ''}>
              <MdMiscellaneousServices></MdMiscellaneousServices>
              <span> LandingPage Management</span>

            </button>
          </li>

          <li>
            <button onClick={() => { resolvefunction(navigate, '/list-campaign', getCampaignListData(reqbodyforRole)) }} className={(pathname && pathname == '/list-campaign') ? 'active' : ''}>
              <MdMiscellaneousServices></MdMiscellaneousServices>
              <span> Campaign Management</span>

            </button>
          </li>


          <li className={(pathname && pathname == '/training-category/list') ? 'active' : '' || (pathname && pathname == '/training-lesson/list') ? 'active' : ''}>
            <button className={(pathname && pathname == '/training-category/list') ? 'active' : '' || (pathname && pathname == '/training-lesson/list') ? 'active' : ''}>
             
              <MdLocalLibrary></MdLocalLibrary>
              <span>Training Management</span>

            </button>
            <ul>
              <li>
                <button onClick={() => { resolvefunction(navigate, '/training-category/list', getroledata(reqbodyforRole)) }} className={(pathname && pathname == '/training-category/list') ? 'active' : ''}>
                  Training
                </button>
              </li>
              <li>
                <button onClick={() => { resolvefunction(navigate, '/training-lesson/list', getroledata(reqbodyforRole)) }} className={(pathname && pathname == '/training-lesson/list') ? 'active' : ''}>
                  Lesson
                </button>
              </li>
            </ul>
          </li>

          <li>
            <button onClick={() => { resolvefunction(navigate, '/lead-list', getleaddata(reqbodyforRole)) }} className={(pathname && (pathname == '/lead-list' || pathname == `/lead-folder-view/${id}` || pathname == `/lead-edit/${id}` || pathname == '/lead-add')) ? 'active' : ''}>
        
              <MdMiscellaneousServices></MdMiscellaneousServices>
              <span> Lead Management</span>

            </button>
          </li>

          <li className={(pathname && pathname == '/jobticket-category') ? 'active' : '' || (pathname && pathname == '/training-lesson/list') ? 'active' : ''}>
            <button className={(pathname && pathname == '/jobticket-category') ? 'active' : '' || (pathname && pathname == '/training-lesson/list') ? 'active' : ''}>
              {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/menuIcon/role_management_img.webp'></img> */}
        {/* <MdBookmarks />
        <span>jobtickets Management</span> */}

      {/* </button>
      <ul>
        <li>
          <button onClick={() => { resolvefunction(navigate, '/jobticket-category', getroledata(reqbodyforRole)) }} className={(pathname && pathname == '/jobticket-category') ? 'active' : ''}>
            jobticket category
          </button>
        </li>
        <li>
          <button onClick={() => { resolvefunction(navigate, '/training-lesson/list', getroledata(reqbodyforRole)) }} className={(pathname && pathname == '/training-lesson/list') ? 'active' : ''}>
            Lesson
          </button>
        </li>
      </ul> */}
    


       
    < AdminMenu />

      </div >
    </div >
  )
}
