import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"



export const getContractSlagData = createAsyncThunk("contractslagListData", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/contract-slug-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const contractSlugEditdata = createAsyncThunk("contractSlugEditdata", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/contract-slug-list-count", requestOptions);
    const respdata = await response.json();
    return respdata;


})


export const getContractListData = createAsyncThunk("contractListData", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }

    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/contract-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})





export const contractEditdata = createAsyncThunk("contractEditdata", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/contract-list-count", requestOptions);
    const respdata = await response.json();
    return respdata;


})


// ------- trackContractOpen ---------
export const trackContractOpen = createAsyncThunk("contractEditdata", async (getApi) => {

    console.log("reqbody==>", getApi);

    console.log("process.env==>", process.env);
    const response = await fetch(getApi);
    const respdata = await response.json();
    return respdata;


})
// ------------------------------------





const contractReducer = createSlice({
    name: 'contractReducer',
    initialState: {

        submitting: false,
        loading: false,
        editloading: false,
        contractSlagData: [],
        editcontractSlagData: [],
        contractListData: [],
        editcontractData: [],
        contractSlugAutocompleteData: [],




    },
    reducers: {
        clearContractSlagedata(state, action) {
            state.contractSlagData = []
        },
        clearContractSlugEditdata(state, action) {
            state.editcontractSlagData = []
        },
        clearContractdata(state, action) {
            state.contractListData = []
        },
        clearContractEditdata(state, action) {
            state.editcontractData = []
        },
    },
    extraReducers: {
        /// #################### Training Listing ###############################
        [getContractSlagData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },
        [getContractSlagData.pending]: (state, action) => {


            console.log("getContractSlagData=========================>")
            state.loading = true;

        },
        [getContractSlagData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.contractSlagData = action.payload.results.res;
                state.loading = false;

            }

        },




        [contractSlugEditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [contractSlugEditdata.pending]: (state, action) => {


            console.log("contractSlugEditdata=========================>")
            state.editloading = true;

        },
        [contractSlugEditdata.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.editcontractSlagData = action.payload.results.res;
                state.editloading = false;

            }

        },






        [getContractListData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },
        [getContractListData.pending]: (state, action) => {


            console.log("getContractListData=========================>")
            state.loading = true;

        },
        [getContractListData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.contractListData = action.payload.results.res;
                state.loading = false;

            }

        },



        [contractEditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [contractEditdata.pending]: (state, action) => {


            console.log("contractEditdata=========================>")
            state.editloading = true;

        },
        [contractEditdata.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.editcontractData = action.payload.results.res;
                state.editloading = false;

            }

        },



        // ------------- 
        [trackContractOpen.rejected]: (state, action) => {
            state.successAction = false;
        },
        [trackContractOpen.pending]: (state, action) => {
            state.successAction = true;

        },
        [trackContractOpen.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                
                state.successAction = false;

            }

        },


        




    }

})

export default contractReducer.reducer;
export const { clearContractSlagedata, clearContractSlugEditdata, clearContractdata, clearContractEditdata } = contractReducer.actions;

