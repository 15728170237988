import { LinearProgress } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import store from '../../../../store';
import { getProductData } from '../../Training/TrainingReducer';
import { FetchRoles } from '../../userManagement/userList/userListReducer';
import { clearContractEditdata, contractEditdata, getContractListData } from '../ContractReducer';

const ContractAddEdit = () => {
  const navigate = useNavigate()
  const pathname = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log("_id++++++", id)
  const [programdataArray, setProgramdataArray] = useState([]);





  const resolved = useSelector(state => state.ResolveReducer?.resolved[`/edit-contract/${id}`] ? state.ResolveReducer.resolved[`/edit-contract/${id}`] : false)
  const loading = useSelector(state => state.contractReducer?.editloading ? state.contractReducer.editloading : false);

  const goBackClicked = useSelector(state => state.formSlice?.customButtonClicked?.ContractAddEditForm?.goback001 ? state.formSlice.customButtonClicked.ContractAddEditForm.goback001 : false);
  const editdata = useSelector(state => (state.contractReducer?.editcontractData && state.contractReducer?.editcontractData.length > 0) ? state.contractReducer.editcontractData[0] : null);
  const getProgramCategory = useSelector(state => (state.trainingSlice?.getproduct && state.trainingSlice?.getproduct.length > 0) ? state.trainingSlice.getproduct : [])
  const getRoles = useSelector(state => (state.userManagment?.fetchRoles && state.userManagment?.fetchRoles.length > 0) ? state.userManagment.fetchRoles : [])

  let leadroleObj={val:"is_lead",name:"Lead"}
  let roleData=cloneDeep(getRoles)
  roleData?.push(leadroleObj)


  let reqbodyforContract = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "updatedon_datetime",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }


  const dataform = {
    id: 'ContractAddEditForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "contract-manage/create-update-contract",

    urlPathOnSuccessfulFormSubmission: "/contract-list",
    submitBtnName: (editdata !== null) ? "Update" : "Submit",
    cancelBtnName: "Cancel",
    className: 'inputBlock inputBlock3line',
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {

      _id: (id !== undefined && id !== null) ? id : undefined
    },

    fields: [
      {
        id: 0,
        heading: "Contract Name",
        label: "Contract Name",
        name: "name",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock3line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.name !== undefined) ? editdata.name : undefined,

      },
      {
        id: 8,
        heading: "Roles",
        label: "Roles",
        // label: "Select Program",
        name: "roles",
        className: 'inputBlock inputBlock3line',

        type: 'select',
        multiple: true,
        checkbox: true,
        values: roleData,
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        // sx: { m: 1, minWidth: 120 },
        defaultValue: (editdata && editdata.roles !== undefined) ? editdata.roles : undefined,
      },
      {
        id: 18,
        label:  "Required Slug",
        heading:  "Required Slug",
        name: "slug_variable",
        type: "autoComplete",
        className: 'inputBlock inputBlock3line',
        multiple: true,
        base_url: process.env.REACT_APP_API_URL + "contract-manage/fetch-contract-slug",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {
            // "status": 1
          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.slug_variable !== undefined) ? editdata.slug_variable : undefined,
      },
      {
        id: 27,
        heading: "Sent Email",
        label: "Sent Email",
        name: "send_email",
        type: "autoComplete",
        className: 'inputBlock inputBlock3line',
        base_url: process.env.REACT_APP_API_URL + "email-template/auto-complete-email-template-subject",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.send_email !== undefined) ? editdata.send_email : undefined,


      },
      {
        id: 16,
        heading: "Confirmation Email For Admin",
        label: "Confirmation Email",
        name: "confirm_email_admin",
        type: "autoComplete",
        className: 'inputBlock inputBlock3line',
        base_url: process.env.REACT_APP_API_URL + "email-template/auto-complete-email-template-subject",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.confirm_email !== undefined) ? editdata.confirm_email : undefined,


      },
      {
        id: 16,
        heading: "Confirmation Email For User",
        label: "Confirmation Email",
        name: "confirm_email_user",
        type: "autoComplete",
        className: 'inputBlock inputBlock3line',
        base_url: process.env.REACT_APP_API_URL + "email-template/auto-complete-email-template-subject",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.confirm_email !== undefined) ? editdata.confirm_email : undefined,


      },
      {
        id: 19,
        heading: "Remainder Email",
        label: "Remainder Email",
        name: "remainder_email",
        type: "autoComplete",
        className: 'inputBlock inputBlock3line',
        multiple: false,
        base_url: process.env.REACT_APP_API_URL + "email-template/auto-complete-email-template-subject",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.remainder_email !== undefined) ? editdata.remainder_email : undefined,


      },
       {
        id: 17,
        heading: "Rejection Email For Admin",
        label: "Rejection Email",
        name: "reject_email_admin",
        type: "autoComplete",
        className: 'inputBlock inputBlock3line',
        base_url: process.env.REACT_APP_API_URL + "email-template/auto-complete-email-template-subject",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.reject_email !== undefined) ? editdata.reject_email : undefined,


      },
      {
        id: 17,
        heading: "Rejection Email For user",
        label: "Rejection Email",
        name: "reject_email_user",
        type: "autoComplete",
        className: 'inputBlock inputBlock3line',
        base_url: process.env.REACT_APP_API_URL + "email-template/auto-complete-email-template-subject",
        reqBody: {
          // "formId": 'teamsearch',
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {

          }
        },
        sx: { m: 1, minWidth: 300 },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.reject_email !== undefined) ? editdata.reject_email : undefined,


      },
      {
        id: 28,
        heading: "Related Program",
        label: "Related Program",
        // label: "Select Program",
        name: "program",
        className: 'inputBlock inputBlock3line',

        type: 'select',
        multiple: true,
        checkbox: true,
        values: programdataArray,
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        // sx: { m: 1, minWidth: 120 },
        defaultValue: (editdata && editdata.program !== undefined) ? editdata.program : undefined,
      },
 
      {
        id: 30,
        heading: "Slug Error Message",
        name: "error_message",
        placeholder: "Please type here...",
        className: 'inputBlock inputBlock1line',
        type: 'textarea',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.description !== undefined) ? editdata.description : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"


      },
      {
        id: 3,
        heading: "Description",
        name: "description",
        placeholder: "Please type here...",
        className: 'inputBlock inputBlock1line',
        type: 'textarea',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.description !== undefined) ? editdata.description : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"


      },
      {
        id: 1,
        heading: "Template",
        label: "Template",
        name: "template",
        type: "sunEditor",
        // className: "formbox ckediterblock countDiv__column--col1",
        className: "inputBlock inputBlock1line",
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.template !== undefined) ? editdata.template : undefined,
        // rules: { required: true, minLength: 15 },
        // errorMessage: {
        //   required: "This Field is Required",
        // },

        placeholder: "Please type here...",
        ////////////////////////////// Mandatory or no options will be available /////////////////////
        editorWidth: 300,
        buttonListOptions: [
          ["undo", "redo", "font", "fontSize", "formatBlock"],
          [
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "removeFormat",
          ],
          [
            "fontColor",
            "hiliteColor",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "table",
          ],
          [
            "link",
            "image",
            "video",
            "fullScreen",
            "showBlocks",
            "codeView",
            "preview",
            "print",
            "save",
          ],
        ],
      },



      {
        id: 2,
        // heading: "Status",
        name: "status",
        className: "inputBlock1line statusinput",
        type: 'singleCheckbox',
        values: { key: 0, val: 'Active' },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.status !== undefined) ? editdata.status : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"



      },

      {
        id: 12,
        // heading: "Is Amendment",
        name: " is_amendment",
        className: "inputBlock1line statusinput",
        type: 'singleCheckbox',
        values: { key: 0, val: 'Is Amendment' },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.is_amendment !== undefined) ? editdata.is_amendment : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"



      },





    ],
    customButtons: [
      {
        id: 'goback001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },
    ]

  }

  useEffect(() => {
    if (goBackClicked === true) {
      resolvefunction(navigate, '/contract-list', getContractListData(reqbodyforContract))
    }

  }, [goBackClicked])

  useEffect(() => {


    if (!resolved) {
      if (id !== undefined && pathname !== null && pathname.pathname !== undefined && pathname.pathname.includes('edit')) {

        dispatch(contractEditdata(id))

      }
    }




    return () => {
      store.dispatch(clearContractEditdata())
      dispatch(resetresolvenew())

    }
  }, [])

  /*******************for product**************** */

  useEffect(() => {
    store.dispatch(getProductData());
    store.dispatch(FetchRoles());


  }, []);

  useEffect(() => {
    if (getProgramCategory && getProgramCategory.length > 0) {
      console.log("getProgramCategory", getProgramCategory);
      let arr = [];
      for (let j in getProgramCategory) {
        arr.push({ val: getProgramCategory[j].key, name: getProgramCategory[j].label })
      }
      setProgramdataArray(arr)
    }
  }, [getProgramCategory])


  // useEffect(() => {
  //   if (getRoles && getRoles.length > 0) {
  //     console.log("getRoles", getRoles);
  //     let arr = [];
  //     for (let j in getRoles) {
  //       arr.push({ val: getRoles[j].key, name: getRoles[j].label })
  //     }
  //     setProgramdataArray(arr)
  //   }
  // }, [getRoles])






  return (


    <>


      <h1 className='page_heading'>Contract  {id !== undefined ? 'Edit' : 'Add'} </h1>


      <div className="dataform2_wrapper">
        {loading == true ? <LinearProgress /> : false}
        <div className='adminformbody'>
          {/* <Form formData={dataform} />   */}
          {id !== undefined && editdata && (editdata.length > 0 || Object.keys(editdata).length > 0) ? <> <Form formData={dataform} /> </> : !id && <Form formData={dataform} />}
        </div>
      </div>

    </>




  )
}

export default ContractAddEdit