import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Grid, LinearProgress, Snackbar } from '@mui/material';
import { useCookies } from "react-cookie";
import StateArray from '../../../../assets/json/state';
import fetchUserDataReducer, { fetchUserData } from '../fetchUserDataReducer';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { UserActivity } from './folderViewReducer';
import { FetchRoles } from '../userList/userListReducer';
import Interview from './Interview';
import LoginIcon from '@mui/icons-material/Login';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const RightPanelBlock = (props) => {
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

    // ---- States here -----
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const



    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [paramId])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)
    console.warn("<<<<< folderView right panel tagsinfo >>>>>", props.tagsinfo)
    console.warn("<<<<< folderView right panel stageinfo >>>>>", props.stagedata)


    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

    const loading = useSelector(state => (state?.userFolderViewReducer?.tagloading) ? state.userFolderViewReducer.tagloading : false)

    const loader = useSelector(state => (state?.userFolderViewReducer?.stageloading) ? state.userFolderViewReducer.stageloading : false)



    
    // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)



    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };



    return (
        <>


            <div className='blocks'>
                <h3> <ShowChartIcon /> Stage Distribution</h3>

                {loader ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}

                {props.stagedata.length > 0 ?

                    (props?.stagedata?.map((x) =>
                        <span className='stageChipB'>
                            <Chip label={x.stage_name} sx={{ margin: '10px' }} variant="outlined" />
                            <QuestionMarkIcon className='qIcon' />

                            <span className='chipDesBuble'>
                                {x.stage_name} <br />
                                Added by: {x.added_by_name} On {format(x.createdon_datetime, 'dd-MM-yyyy HH:mm')}
                            </span>

                        </span>))

                    :
                    (<p> No stage assigned!</p>)}

            </div>


            <div className='blocks'>
                <h3> <LocalOfferIcon /> Tags</h3>

                {loading ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


                {props?.tagsinfo.length > 0 ? (<div>
                    {props?.tagsinfo.length > 0 ? (

                        props?.tagsinfo?.map((x) =>
                            <ul className='Fv-tags'>
                                <li className='tagsBlck'>

                                    <Chip label={x.tag_name} variant="outlined" />
                                    <QuestionMarkIcon className='qIcon' />
                                    <p className='chipDesBuble'>
                                        {x.tag_description} <br />
                                        Added by : {x.added_by_name} On {format(x.createdon_datetime, 'dd-MM-yyyy HH:mm')}
                                    </p>

                                </li>
                            </ul>

                        )) : (<p> No tag assigned!</p>)}

                </div>) : <p> No tag assigned!</p>}

            </div>





            {open ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={queryParamId ? "Updated Sucessfully" : " "}
            // action={action}
            />) : ""}

        </>
    )
}

export default RightPanelBlock;






