import { Box, Button, LinearProgress, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import store from '../../store';
import { fetchbluecodelead } from "./LandingPage/LandingpageReducer";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from "../../form/Form";
import { setSnackbar } from "../Layout/layoutReducer";


function Success() {
  const dispatch = useDispatch()
  const { leadid } = useParams();
  const [open, setOpen] = useState(false)
  // console.log("leadid", leadid);
  const editdatablue = useSelector(state => (state.LandingpagefontReducer?.bluefetchdata) ? state.LandingpagefontReducer?.bluefetchdata : null)
  const loading = useSelector(state => (state.LandingpagefontReducer?.bluecoseloader) ? state.LandingpagefontReducer?.bluecoseloader : false)
  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.formblueediitt) ? state.formSlice?.formSubmissionState?.formblueediitt : 0)

  useEffect(() => {
    if (leadid) dispatch(fetchbluecodelead(leadid))
  }, [leadid])
  useEffect(() => {
    console.log("editdatablue", editdatablue);
  }, [JSON.stringify(editdatablue)])


  const modalopen = () => {
    setOpen(true)
  }
  const formblueedit = {
    id: 'formblueediitt',
    formtype: "edit",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "bclusers/addupdate-users",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    // resetBtnName: "Reset",
    formAdditionalSubmissionData: {
      '_id': editdatablue && editdatablue._id ? editdatablue._id : undefined,
      "status": 0,
      // "roles": { is_rep: 1 },

    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "First Name",
        label: " First Name",
        name: "first_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.first_name !== undefined) ? editdatablue.first_name : undefined,

      },
      {
        id: 2,
        heading: "Last Name",
        label: "Last Name",
        name: "last_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.last_name !== undefined) ? editdatablue.last_name : undefined,

      },
      {
        id: 3,
        heading: "Email",
        label: "Email",
        name: "email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.email !== undefined) ? editdatablue.email : undefined,

      },

      {
        id: 4,
        heading: "Phone",
        label: "Phone",
        name: "phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.phone !== undefined) ? editdatablue.phone : undefined,

      },
      {
        id: 5,
        heading: "Prospect Medical Facility Business Name (Blue Coast Advisor Contact Info)",
        label: "Prospect Medical Facility Business Name (Blue Coast Advisor Contact Info)",
        name: "prospectbusinessname",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectbusinessname !== undefined) ? editdatablue.prospectbusinessname : undefined,

      },

      {
        id: 6,
        heading: "Prospect Title",
        label: "Prospect Title",
        name: "prospecttitle",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospecttitle !== undefined) ? editdatablue.prospecttitle : undefined,
      },

      {
        id: 7,
        heading: "Prospect First Name",
        label: "Prospect First Name",
        name: "prospectfirstname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectfirstname !== undefined) ? editdatablue.prospectfirstname : undefined,
      },
      {
        id: 8,
        heading: "Prospect Last Name",
        label: "Prospect Last Name",
        name: "prospectlastname",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectlastname !== undefined) ? editdatablue.prospectlastname : undefined,
      },

      {
        id: 9,
        heading: "Prospect Email",
        label: "Prospect Email",
        name: "prospectemail",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectemail !== undefined) ? editdatablue.prospectemail : undefined,
      },

      {
        id: 10,
        heading: "Prospect Contact Number ",
        label: "Prospect Contact Number ",
        name: "prospectcontactnumber",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.prospectcontactnumber !== undefined) ? editdatablue.prospectcontactnumber : undefined,
      },
      {
        id: 11,
        heading: "Type of medical facility (medical practice, surgical center, urgent care, hospital, medical practice group)",
        name: "medicalfacilitytype",
        className: 'form_radio',
        values: [{ key: 'Medical Practice', val: 'Medical Practice' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Hospital', val: 'Hospital' }, { key: 'Medical Practice Group', val: 'Medical Practice Group' }, { key: 'Other', val: 'Other' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.medicalfacilitytype !== undefined) ? editdatablue.medicalfacilitytype : undefined,
        // defaultValue: "No"
      },

      // {
      //   id: 22,
      //   heading: "Type of facility",
      //   name: "facility_type",
      //   className: 'form_radio',
      //   values: [{ key: 'Med Practice', val: 'Med Practice' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Hospital', val: 'Hospital' }, { key: 'Other', val: 'Other' }],
      //   type: 'radio',
      //   // hint: 'Yes/No',
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },



      // {
      //   id: 1,
      //   heading: "Name of medical facility",
      //   label: "Name of medical facility",
      //   name: "medical_facility_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },
      // },
      // {
      //   id: 2,
      //   heading: "Name of their relationship at facility and that persons title (would be good to have this fill able so that they can type details)",
      //   label: "Name of their relationship at facility and that persons title",
      //   name: "relationship_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true},
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },


      {
        id: 12,
        heading: "What is the nature of your relationship with the prospect?  ",
        name: "natureofrelationship",
        className: 'form_radio',
        values: [{ key: 'Client', val: 'Client' }, { key: 'Client/Friend', val: 'Client/Friend' }, { key: 'Friend', val: 'Friend' }, { key: 'Cold', val: 'Cold' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.natureofrelationship !== undefined) ? editdatablue.natureofrelationship : undefined,
        // defaultValue: "No"
      },



      {
        id: 13,
        heading: "Approx gross receipts with top 3 insurers (2 mil minimum)",
        label: "Approx gross receipts with top 3 insurers (2 mil minimum)",
        name: "approxgrossreciept",
        className: 'formfild',
        type: "text",
        // inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.approxgrossreciept !== undefined) ? editdatablue.approxgrossreciept : undefined,

      },
      {
        id: 14,
        heading: "Any other information we should know?",
        label: "Any other information we should know?",
        name: "other_info",
        placeholder: 'Any other information we should know?',
        className: 'formfild textareafild',
        type: "textarea",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // },
        defaultValue: (editdatablue && Object.keys(editdatablue).length > 0 && editdatablue.other_info !== undefined) ? editdatablue.other_info : undefined,

      }



    ]

  };


  useEffect(() => {
    console.log("submissionState", submissionState);
    if (submissionState && submissionState == 2) {
      dispatch(
        setSnackbar({ open: true, message: 'Updated Successfully !!', status: 'success' })
      )
      editapicall()

    }
  }, [submissionState])

  const editapicall = async () => {
    dispatch(fetchbluecodelead(leadid))
    setOpen(false)
  }
  return (
    <div>
      <div className='thankYou_wrpr'>
        <div className='thankYou_bgdesgn'>
          <div className="bgdesgn1"></div>
          <div className="bgdesgn2"></div>
          <div className="bgdesgn3"></div>
          <div className="bgdesgn4"></div>
          <div className="bgdesgn5"></div>
          <div className="bgdesgn6"></div>
          <div className="bgdesgn7"></div>
          <div className="bgdesgn8"></div>
        </div>


        <div className='thankyou-container'>
          <div className='thankyou-block'>

            <div className='content center'>
              {leadid ? <h1>Your submission is successful .</h1> : <h1>Thank You For Submited Form</h1>}
              {/* {!params && !params.rep && <p>Greeting for your interest in Aspire. Our team will verify your details and contact you as soon as possible.</p>}  */}
              {leadid ? <div className='thankyou-containerSub'>
                <div className='thankyou-blockSub'>


                  {loading && <Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>}
                  <div className="landingpage9successBody">
                    {/* <div className="editiconinsucccess" onClick={() => modalopen()}>
                      <EditIcon />

                    </div> */}
                    {!loading && editdatablue && <div className="landingpage9success">
                      {/* <h1>Your Slot {page === "booking" ? 'Booked' : page === "reschedule" ? "Re-Scheduled" : "Canceled"} Successfully!</h1> */}


                      <p><strong>Name :</strong> <span> {editdatablue.first_name} {editdatablue.last_name} </span> </p>
                      <p><strong> Email:</strong> <span> {editdatablue.email} </span> </p>
                      <p><strong> Phone:</strong> <span> {editdatablue.phone} </span> </p>
                      <p><strong> Prospect Medical Facility Business Name <span>(Blue Coast Advisor Contact Info) :</span></strong> <span> {editdatablue.prospectbusinessname} </span> </p>
                      <p><strong> Prospect Title:</strong> <span> {editdatablue.prospecttitle} </span> </p>

                      <p><strong> Prospect First Name:</strong> <span> {editdatablue.prospectfirstname} </span> </p>

                      <p><strong> Prospect Last Name:</strong> <span> {editdatablue.prospectlastname} </span> </p>
                      <p><strong> Prospect Email:</strong> <span> {editdatablue.prospectemail} </span> </p>
                      <p><strong> Prospect Contact Number:</strong> <span> {editdatablue.prospectcontactnumber} </span> </p>
                      <p><strong> Type of medical facility : </strong> <span> {editdatablue.medicalfacilitytype} </span> </p>
                      {/* <p><strong>Type of facility:</strong> <span> {editdatablue.facilitytype} </span> </p> */}
                      <p><strong> What is the nature of your relationship with the prospect?:</strong> <span> {editdatablue.natureofrelationship} </span> </p>
                      <p><strong> Approx gross receipts with top 3 insurers ($2M minimum):</strong> <span> {editdatablue.approxgrossreciept} </span> </p>
                      {editdatablue.other_info && <p><strong> Any other information we should know? :</strong> <span> {editdatablue.other_info} </span> </p>}

                    <div className="editiconinsucccess" onClick={() => modalopen()}>
                      {/* <EditIcon />  */}
                      Edit

                    </div>
                  </div>}
                
                    
                  </div>


                </div>
              </div> : <p>Greeting for your interest in Aspire. Our team will verify your details and contact you as soon as possible.</p>}
            </div>
          </div>
        </div>
      </div>

      {editdatablue && <Modal
        className="modalblock"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box className="BoxMui_modal successmodal9" sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}>
          <h2>BLUECOAST SAVINGS AND UNDERPAYMENTS</h2>

          <Form formData={formblueedit} />

          {/* -------------------------------------- */}



          <Button onClick={() => setOpen(false)} className="closeModalBtn">
            <Tooltip title="Close" placement="right-start">

              <CloseIcon />
            </Tooltip>
          </Button>
        </Box>
      </Modal>}
    </div>
  );
}

export default Success
