
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, upgradeRole } from './userListReducer';
import SnackBarComp from '../../../../commoncomponents/snackbar/snackBar';



const UpgradeRoleModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const [userRoles, setUserRoles] = React.useState([]);
    const [copyUserRoles, setcopyUserRoles] = React.useState([]);
    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [snackFire, setSnackFire] = useState(false);  //--- Open SnackBar ----//
    const [resMessage, setResMessage] = useState(null);  //--- resMessage  ----//
    const [endpointResStatus, setEndpointResStatus] = useState(null);  //--- resMessage  ----//



    // console.warn("<<<<<< props goes here >>>>>>", props)
    // console.warn("<<<<<< cookies goes here >>>>>", userInfo)

    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)

    const roleUpgraded = useSelector(state => (state.userManagment?.roleUpgraded) ? state.userManagment.roleUpgraded : false)

    const userRolesNewData = useSelector(state => (state.userManagment?.userRolesNew
    ) ? state.userManagment.userRolesNew : []);


    console.warn("<<<<<<<<<<<< roles here >>>>>>>>>>", props.role)

    const value = userRolesNewData.filter((data) => data.key == 'is_aer')
    console.warn("<<<<<<<< value goes here >>>>>>>", value)
    const userId = props.userId ? props.userId : undefined

    useEffect(() => {
        props.tooglefunc(false);
        setopen(false)


    }, [])


    useEffect(() => {
        if (roleUpgraded) {
            setopen(true)
            setResMessage("Role Upgraded to AER")
            setEndpointResStatus("success")

            setTimeout(() => {
                setopen(false)

            }, 6000);
        }
    }, [roleUpgraded])



    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.tooglefunc(false);
        // setOpenDropDown(false)
    }


    // ------------- Hnadle Chnage ----------
    const handleChange = (e) => {
        // event.preventDefault();
        // console.warn("<<<<<<<<<< value from checkbox >>>>>", e)

        if (e) {
            const copyRoles = [...props.role]

            // console.warn("<<<<< copyRoles goes here >>>> ", copyRoles)


            const payload = copyRoles.map((val) => {
                // console.warn("<<<<< copyRoles  map val goes here >>>> ", val)
                const data = { ...val }
                if (data.key.includes('is_aer')) {
                    data.status = 1
                }else{
                    data.status = 0

                }
                // data.key=='is_aer'?

                return data
            })

            // console.warn("<<<<< final payload true goes here >>>> ", payload)

            dispatch(upgradeRole({ _id: userId, roles_data: payload }));


        } 
        // else {

        //     const copyRoles = [...props.role]

        //     console.warn("<<<<< copyRoles goes here >>>> ", copyRoles)


        //     const payload = copyRoles.map((val) => {
        //         console.warn("<<<<< copyRoles  map val goes here >>>> ", val)
        //         const data = { ...val }
        //         if (data.key.includes('is_aer')) {
        //             data.status = 0
        //         }
        //         // data.key=='is_aer'?

        //         return data
        //     })

        //     console.warn("<<<<< final payload false goes here >>>> ", payload)

        //     dispatch(upgradeRole({ _id: userId, roles_data: payload }));


        // }



    };

    // ----------------------------



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.open}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {loader ? <LinearProgress /> : ""}
                        Upgrade Role
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}

                    <form>
                        <FormControlLabel control={
                            <Checkbox
                                defaultChecked={value[0].status == 1 ? true : false}
                                onChange={(e) => handleChange(e.target.checked)}
                            />} label="AER" />
                    </form>


                    {/* -------------------------------------- */}

                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>



            {/* --------- SnackBar -------- */}
            {resMessage && open? <SnackBarComp
                setSnackFire={setSnackFire}
                resMessage={resMessage}
                snackFire={open}
                endpointResStatus={endpointResStatus}
            /> : ""}
            {/* ------------ */}

        </>
    )
}


export default UpgradeRoleModal;



