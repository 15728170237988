import { Drawer, IconButton, Paper, Tooltip } from '@mui/material'
import React, { useEffect, useState,useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '../../../../form/Form';



const FDSearch = ({ type, open, close,searchFields }) => {

  

//----------------------search form configration---------------------
  const dataform = useRef({
    id: 'Folder_View_Search',
    submitBtnName: "Search",
    submitBtnEndIcon: 'search_icon',
    resetForm: true,
    resetBtnName: "Refresh",
    resetBtnEndIcon: 'refresh',
    endPointBodyWrapper: "searchcondition",
    formButtonClass: "submitbtnsection",
    formWrapperClass: "formWrapperClass formCls",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {

    },


    fields: [],

  })

  //----------------------------------------------------------

 //-----------------send input fields in search form------------------
useEffect(() => {
  dataform.current.fields=searchFields.map((data) => data)
}, [])
//--------------------------------------------------------------------


  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => close(false)}
    >

      <Paper elevation={7} className='list_search team_management_list_search search_img'>


        <div className="searchBarHead DrawerBox">
          <h2 className="searchBarHeading" style={{textTransform:"capitalize"}}>Search &nbsp; {type?type.split("_").join(" "):""}</h2>
          <span className="searchBarCloseIcon">
            <Tooltip title='Close' >
              <IconButton >
                <CloseIcon className="closeDrawer" onClick={() => close(false)} />
              </IconButton>
            </Tooltip>
          </span>
        </div>

        <Form formData={dataform.current}  />
      </Paper>
    </Drawer>

  )
}

export default FDSearch