import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import ListingTable from '../../../../listing/listing';
import { clearprogramdata } from '../../Program/ProgramReducer';
import store from "../../../../store"


import { getformdata, getformeditdata } from "../FormManagementReducer"
import { resolvefunction } from '../../../../helper/helperFunctions';
import FormPreviewModal from '../../../../commoncomponents/modal/FormPreviewModal';

function FormList() {


  const navigate = useNavigate();
 /****************for preview form field modal*********************** */
  const formpreviewclick = useSelector(state => (state.tableSlice?.actionLoading && Object.keys(state.tableSlice?.actionLoading).length > 0 && state.tableSlice?.actionLoading.Formlist?.FormView && state.tableSlice?.actionLoading.Formlist?.FormView == 2) ? true : false);

  const formpreviewdata = useSelector(state => (state.tableSlice?.actionData && Object.keys(state.tableSlice?.actionData).length > 0 && state.tableSlice?.actionData.Formlist.FormView && state.tableSlice?.actionData.Formlist.FormView.length > 0) ? state.tableSlice?.actionData.Formlist.FormView : null);

  const [openfieldpreview, setOpenfieldpreview] = useState()

  useEffect(() => {
    console.log("formpreviewclick", formpreviewclick, formpreviewdata);

    if (formpreviewclick && formpreviewdata) {
      setOpenfieldpreview(true)
    }
  }, [formpreviewdata, formpreviewclick])



  var modifyTableHeaders =
    [
      { val: "form_name", name: " Name" },
      { val: "heading", name: "Heading" },
      { val: "programname", name: " Related Programs" },
     
      // { val: "priority", name: "Priority" },
      { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active" } },
      // { val: "createdon_datetime", name: "Creation Time", type: "datetime", format: "DD/MM/YYYY" },
      { val: "updatedon_datetime", name: "Updation Time", type: "datetime", format: "DD/MM/YYYY" },


    ];

  let tableDataForUsers = useRef({
    tableId: "Formlist",
    tableTitle: "Form List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "manage-form/form-list-count",
      deleteSingleUserEndpoint: "manage-form/delete-form",
      tableCountEndpoint: "manage-form/form-list-count",
    },

    reqBody: {
      "source": "",
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "updatedon_datetime",
        "type": "desc"
      },
      "searchcondition": {},
      "project": {},
      "token": "",
      "count": false
    },
    deleteSingleUserReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      // source: "training_list",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["training_name", "priority", "status", "createdon_datetime", "updatedon_datetime", "training_parent_name"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        modalClassName: "delete_modal",
        className: "delete_modal",
        modalTitle: "<p>Are you sure you want to delete these records?</p>",
        modalHeader: "<p> Alert !! </p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-form/delete-form",
          body: {
            "source": "form_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
            "id": [
              "63bfe68c4b492df5af511c41"
            ]
          }
        }
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        modalClassName: "delete_modal",
        className: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status?</p>",
        modalHeader: "<p> Alert !! </p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-form/status-update-form",
          body: {
            "source": "form_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
            "id": [
              "63c4feedbb9581dba885fb9b"
            ],
            "status": 0
          }
        }
      },
      // {
      //   id: "adduser",
      //   type: "add_icon",
      //   title: "Add Training",
      //   className: "add_user_icon",
      //   default: true,
      // },
    ],
    buttons: [



      {
        id: "edittable9",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },

      // {
      //   id: "deletetable1",
      //   type: "delete",
      //   label: "Delete",
      //   name: "delete",
      // },

      // {
      //   id: "change_status1",
      //   type: "other",
      //   label: "Change Status",
      //   name: "toggle_off ",
      // },




      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        modalClassName: "delete_modal",
        className: "delete_modal",
        modalTitle: "<p>Are you sure you want to delete this record? This can't be undone.</p>",
        modalHeader: "<p> Alert !! </p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "/manage-form/delete-form",
          body: {
            "source": "form_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
            "id": [
              "63bfe68c4b492df5af511c41"
            ]
          }
        }
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        modalClassName: "delete_modal",
        className: "delete_modal",
        modalHeader: "<p>Alert !! </p>",
        modalTitle: "<p>Are you sure you want to change the status?</p>",

        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "/manage-form/status-update-form",
          body: {
            "source": "form_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
            "id": [
              "63c4feedbb9581dba885fb9b"
            ],
            "status": 0
          }
        }
      },
      {
        id: "viewApi",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Form Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-form/form-list-count",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {}
          }
        },
        headers: [
          { val: "form_name", label: "Form Name" },
          { val: "heading",  label: "Heading", name: "Heading" },
          { val: "related_programs", label: "Related Programs" },
      
          // { val: "type", label: "Type" },
          { val: "description", label: "Description" },
          {
            val: "createdon_datetime",
            label: "Creation Time",
            // name: "Creation Time",
            type: "datetime",
            format: "DD/MM/YYYY",
          },
          {
            val: "updatedon_datetime",
            label: "Updation Time",
            // name: "Updation Time",
            type: "datetime",
            format: "DD/MM/YYYY",
          },
        ]
      },


      // {
      //   id: "contentshow",
      //   type: "other",
      //   name: "contentshow",

      // },
      {
        id: "FormView",
        type: "action",
        icon_type: "feed_icon",
        label: "Field",
        name: "Preview Form ",
     
        className:'groupview ',
        base_url: process.env.REACT_APP_API_URL,
        endpoint: "/manage-form/preview-form",
        body: {
          "source": "",

          "searchcondition": {}
        },
        // payloadAttach: ["_id", "type", "email"],
        payloadAttach: ["_id"],
      },


    ],
    deleteModal: {
      modalClassName: "modalblock dltStsModal",
    },


    searchData: {
      heading: " Search Form",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefincounted default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Search",
        submitBtnEndIcon: "search",
        resetBtnName: "Refresh",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Form Name",
            name: "form_name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "manage-form/formnameautocomplete",
            reqBody: {
              "source": "form_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "field": "createdon_datetime",
                "type": "desc"
              },
              "searchcondition": {},
              "project": {},
              "token": "",
              // "count": false
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },

          },
          {
            id: 2,
            label: "Search by Related Programs",
            name: "related_programs",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "/manage-form/programsautocomplete",
            reqBody: {
              "source": "form_list",
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "field": "createdon_datetime",
                "type": "desc"
              },
              "searchcondition": {},
              "project": {},
              "token": "",
              "count": false,
              // "autoCompleteId": "field_name"
            },
            sx: { m: 1, minWidth: 300 },
            payloadFormat: { key: "related_programs"},

          },

          {
            id: 3,
            label: "Search by Status",
            name: "status",
            type: 'select',
            multiple: false,

            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "status" },
          },
          {
            id: 8,
            label: "Search by Creation Start Date",
            name: "createdon_Start_datetime",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            format: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
            className: 'list_search_item countDiv__column--col4 ',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 9,
            label: "Search by Creation End Date",
            name: "createdon_datetime",
            className: 'list_search_item countDiv__column--col4 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            format: 'dd/MM/yyyy',
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },
          {
            id: 10,
            //   heading: "Search By Creation Time",
            label: "Search by Updated on Start Date",
            name: "start_date_by_update",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "updatedon_datetime", param: "$gte" }
          },
          {
            id: 11,
            label: "Search by Updated on End Date",
            name: "end_date_by_update",
            className: 'list_search_item countDiv__column--col6 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "updatedon_datetime", param: "$lte" }
          },
        ]
      }
    },
  });


  let reqbody = {
    "source": "form_list",
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "updatedon_datetime",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": "",
    "count": false
  }

  useEffect(() => {
    // console.log("RoleList", resolve);
    if (!resolved) store.dispatch(getformdata(reqbody));

    return () => {

      store.dispatch(clearprogramdata())
      store.dispatch(resetresolvenew('/form-module/list'))
    }
  }, [])


  let formdatalist = useSelector(state => (state.FormManagementSlice?.formListData && state.FormManagementSlice?.formListData.length > 0) ? state.FormManagementSlice.formListData : [])
  let loader = useSelector(state => (state.FormManagementSlice?.loading) ? state.FormManagementSlice.loading : false)
  const resolved = useSelector(state => state.ResolveReducer.resolved['/form-module/list'] ? state.ResolveReducer.resolved['/form-module/list'] : false)
                 /****************for Edit*********************** */
  const editDatas = useSelector(state => state.tableSlice?.tempRowData?.Formlist ? state.tableSlice.tempRowData.Formlist : {})
  let editformClicked = useSelector(state => state.tableSlice?.customButtonClicked?.Formlist?.edittable9 ? true : false)
  // const editformClicked = useSelector(state => state.tableSlice?.customButtonClicked?.formListData?.edittable9 ? state.tableSlice.customButtonClicked.formListData.edittable9 : false)

  useEffect(() => {
    console.log("editformClicked", editformClicked, editDatas);
    if (editformClicked && Object.keys(editDatas).length > 0) {
      resolvefunction(navigate, `/form-edit/${editDatas._id}`, getformeditdata(editDatas._id))
    }

  }, [editformClicked])

  return (
    <>
      <div className="rolelist_mainwrp">
        <div className='listing_heading'>

          <h1>Form Module</h1>
          <button className='addrole_btn' onClick={() => navigate('/add-form')}>+</button>
        </div>
        <div className='rolelist_mainwrp_Table'>
          {!loader ? (
            <ListingTable
              tableData={tableDataForUsers.current}
              dataset={formdatalist}
              modifyHeaders={modifyTableHeaders}
            />
          ) : (
            <TableSkeleton count={5} />
          )}

        </div>

      </div>
      { /****************for preview form field modal*********************** */ }

      {openfieldpreview && <FormPreviewModal
        openfieldpreview={openfieldpreview}
        setOpenfieldpreview={setOpenfieldpreview}
        dataset={formpreviewdata}
        titel='Preview Form'
      />}
    </>
  )
}

export default FormList
