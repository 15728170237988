import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import ListingTable from "../../../../listing/listing";
import { getfaqdata, clearfaqdata, getfaqeditdata, geteditfaqdata } from './FaqReducer';
import store from '../../../../store';
import { resetresolvenew } from '../../../../helper/ResolveReducer'
import { resolvefunction } from '../../../../helper/helperFunctions';

function Faq() {

  const navigate = useNavigate()


  let responseData = useSelector(state => (state.FaqReducer?.faqdata && state.FaqReducer?.faqdata.length > 0) ? state.FaqReducer.faqdata : [])
  const resolved = useSelector(state => state.ResolveReducer.resolved['/faq-category'] ? state.ResolveReducer.resolved['/faq-category'] : false)

  const editClicked = useSelector(state => state.tableSlice?.customButtonClicked?.faqtable?.editfaq ? state.tableSlice?.customButtonClicked?.faqtable?.editfaq : false)
  const editData = useSelector(state => state.tableSlice?.tempRowData?.faqtable ? state.tableSlice.tempRowData.faqtable : {})

  useEffect(() => {
    console.log("editClicked+++++++++", editClicked, editData);
    if (editClicked && Object.keys(editData).length > 0) {
      resolvefunction(navigate, `/edit-faq/${editData._id}`, geteditfaqdata(editData._id))
    }

  }, [editClicked])
  console.log("editClicked+++++++++", editClicked, editData);


  useEffect(() => { }, [responseData])

  console.log("responseData", responseData);

  useEffect(() => {
    if (!resolved) store.dispatch(getfaqdata(reqbody));
    return () => {
      store.dispatch(clearfaqdata())
      store.dispatch(resetresolvenew('/faq'))
    }
  }, [])

  useEffect(() => {
    store.dispatch(getfaqdata(reqbody));
    return () => {
      store.dispatch(clearfaqdata())
      // store.dispatch(resetresolvenew('/lead-list'))
    }
  }, [])


  var modifyTableHeaders =
    [
      { val: "question", name: "Question" },
      { val: "priority", name: "Priority" },
      { val: "user_role_chip", name: "User Type" },
      { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active", } },
      { val: "createdon_datetime", name: "Created On", type: "datetime", format: "DD/MM/YYYY" },
    ];
  let reqbody = {

    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": "",
    "count": false
  }

  // let [responseData, setresponseData] = React.useState([]);

  const tableData1 = useRef({
    tableId: "faqtable",
    tableTitle: "Faq Listing",
    showReload: true,
    showFilter: true,
    // recieveResponseParam: 'results',
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "manage-faq/faq-list-count",
      deleteSingleUserEndpoint: "manage-faq/faq-delete",
      tableCountEndpoint: "manage-faq/faq-list-count",
    },
    reqBody: {
      "source": "",
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {},
      "project": {},
      "token": "",
      "count": false
    },
    deleteSingleUserReqBody: {
      "source": "users",
      "secret": "AZ|lepL`",
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo"
    },
    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["category_name", "status", "priority", "createdon_datetime"],
    enableRowActionLoader: true,
    paginationType: "stackPagination",
    customBtnHead: [
      {
        id: "deleteMultiple",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-faq/faq-delete",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "editMultiple",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-faq/faq-status-update",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      // {
      //   id: "home",
      //   type: "home_icon",
      //   title: "Home",
      // }
    ],
    buttons: [
      {
        id: "editfaq",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },
      {
        id: "deletejobticketcategory",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-faq/faq-delete",
          body: {
            "source": "lead_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },

      {
        id: "viewBtnAction",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Category Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-faq/faq-list-count",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "fullname" },
            "searchcondition": {}
          }
        },
        headers: [
          { val: "question", label: "Question" },
          { val: "answer", label: "Answer" },
          { val: "priority", label: "Priority" },
          // { val: "status", label: "Status", customVal: {"1":"Active"} },
          { val: "user_role_chip", label: "User Type" },
          { val: "added_by", label: "Added By", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
        ]
      },

      {
        id: "statuschange",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p>Alert !!</p>",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "manage-faq/faq-status-update",
          body: {
            "source": "lead_list",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },


    ],
    deleteModal: {
      modalClassName: "Delete Modal",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },

    searchData: {
      heading: "Search Job Ticket Category",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          // {
          //   id: 1,
          //   // heading: "Search By Full Name",
          //   label: "Search By Category Name ",
          //   name: "category_name",
          //   type: 'text',
          //   className: "inputblock2line",
          //   payloadFormat: { key: "category_name", param: "$regex", options: "i" },

          // },

          {
            id: 1,
            label: "Search By Category Name",
            name: "category_name",
            type: "autoComplete",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-category",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "category_name",
              },
              "searchcondition": {

              }
            },

            payloadFormat: { key: "category_name", param: '$regex', options: 'i' },

          },


          {
            id: 2,
            // heading: "Search By Email",
            label: "Search By priority ",
            type: "autoComplete",
            inputType: "number",
            name: "priority",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-category",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "category_name",
              },
              "searchcondition": {

              }
            },
            payloadFormat: { key: "priority" },

          },


          {
            id: 3,
            // heading: "Search By Email",
            label: "Search By Type ",
            type: "autoComplete",
            name: "user_type",
            className: "inputblock2line",
            base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-user-type-search",
            reqBody: {
              source: "roles",
              sort: {
                type: "desc",
                field: "user_type",
              },
              "searchcondition": {

              }
            },
            payloadFormat: { key: "user_type", param: '$regex', options: 'i' },

          },

          // {
          //   id: 3,
          //   // heading: "Search By Full Name",
          //   label: "Search By Type",
          //   name: "user_role",
          //   type: 'text',
          //   className: "inputblock2line",
          //   payloadFormat: { key: "user_role", param: "$regex", options: "i" },

          // },


          {
            id: 4,
            label: "Search by Status",
            name: "status",
            type: 'select',
            multiple: false,
            other: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            // sx: { m: 1, minWidth: 120 },
            className: 'inputblock2line',
            payloadFormat: { key: "status" },
          },
          // {
          //   id: 3,
          //   label: "Search by User Types",
          //   name: "status",
          //   type: 'select',
          //   multiple: true,
          //   values: [],
          //   sx: { m: 1, minWidth: 120 },
          //   className: 'inputblock2line',
          //   payloadFormat: { key: "status", param: "$in", options: "i" },
          // },

          {
            id: 5,
            label: "Search by Start Date",
            name: "createdon_datetime",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            // format: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
            className: 'inputblock2line',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 6,
            label: "Search by End Date",
            name: "createdon_datetime1",
            className: 'inputblock2line',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'dd/MM/yyyy'
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },


        ]
      }
    },
    // searchBarData: {
    //   heading: "Search Lead",
    //   api: {
    //     url: process.env.REACT_APP_API_URL,
    //     endPoint: "lead-manage/job-cat-list",
    //     tableCountEndpoint: "lead-manage/job-cat-list",
    //     reqBody: {
    //       "condition": {
    //         "limit": 5,
    //         "skip": 0
    //       },
    //       "sort": {
    //         "field": "_id",
    //         "type": "desc"
    //       },
    //       "searchcondition": {},
    //       "project": {},
    //       "token": "",
    //       "count": false
    //     },
    //   },

    //   searchSettings: {
    //     datesearch: [
    //       {
    //         id: 0,
    //         heading: "Search By Creation Time",
    //         startdatelabel: "Start Date",
    //         enddatelabel: "End Date",
    //         className: "inputblock2line",
    //         submit: "Search",
    //         field: "createdon_datetime",
    //       },
    //       // {
    //       //   id: 1,
    //       //   heading: "Search By Updation Time",
    //       //   startdatelabel: "Start Date",
    //       //   enddatelabel: "End Date",
    //       //   className: "formGroup createdon_datetime countDiv__column--col4",
    //       //   submit: "Search",
    //       //   field: "createdon_datetime",
    //       // },
    //     ],
    //     selectsearch: [
    //       {
    //         id: 1,
    //         heading: "Search By Status",
    //         label: "Search By Status",
    //         field: "status",
    //         type: "select",
    //         className: " inputblock2line",
    //         values: ["Active", "Inactive"],
    //         // value: "",
    //       },

    //     ],
    //     textsearch: [
    //       {
    //          id: 2,
    //          heading: "Category Name",
    //          label: "Search By Category Name ",
    //          field: "category_name",
    //          type: "text",
    //          className: " inputblock2line",
    //           // value: "",
    //       },
    //       {
    //         id: 3,
    //         heading: "priority",
    //         label: "Search By Priority ",
    //         field: "priority",
    //         type: "text",
    //         inputType: "number",
    //         className: " inputblock2line",
    //         // value: "",
    //       },


    //     ],
    //     // autoCompleteSearch: [

    //     //   {
    //     //     id: 1,
    //     //     heading: "Search By Name",
    //     //     label: "Search Name",
    //     //     field: "fullname",
    //     //     //  values: ["Surgical Wound","Pressure Injuries"],
    //     //     values: "",
    //     //     preload: true,
    //     //     multiple: false,
    //     //     className: "inputblock2line",
    //     //     serverSearchData: {
    //     //       base_url: process.env.REACT_APP_API_URL,
    //     //       endpoint: "lead-manage/auto-complete-lead-search",
    //     //       reqBody: {
    //     //         source: "lead_list",
    //     //         sort: {
    //     //           type: "desc",
    //     //           field: "fullname",
    //     //         },
    //     //         searchcondition: {},
    //     //       },
    //     //     },
    //     //   },


    //     //   {
    //     //     id: 2,
    //     //     heading: "Search By Email",
    //     //     label: "Search By Email",
    //     //     field: "email",
    //     //     //  values: ["Surgical Wound","Pressure Injuries"],
    //     //     values: "",
    //     //     preload: true,
    //     //     multiple: false,
    //     //     className: "inputblock2line",
    //     //     serverSearchData: {
    //     //       base_url: process.env.REACT_APP_API_URL,
    //     //       endpoint: "lead-manage/auto-complete-lead-search",
    //     //       reqBody: {
    //     //         source: "lead_list",
    //     //         sort: {
    //     //           type: "desc",
    //     //           field: "email",
    //     //         },
    //     //         searchcondition: {},
    //     //       },
    //     //     },
    //     //   },


    //     // ],
    //   },
    // },
  })



  return (
    <div>
      <div className='rolelist_mainwrp'>
        <div className='listing_heading'>
          <h1>Faq</h1>
          {/* <button className='addrole_btn'>Add Role</button> */}
          <button className='addrole_btn' onClick={() => navigate('/add-faq')}><AddIcon /></button>
        </div>
        <div className='rolelist_mainwrp_Table'>
          {(responseData && responseData.length > 0) ? <ListingTable tableData={tableData1.current} dataset={responseData} modifyHeaders={modifyTableHeaders} /> : <p>No Records Found</p>}

        </div>


      </div>
    </div>
  )
}

export default Faq
