import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '../../../../form/Form'
import store from '../../../../store';
import { setSnackbar } from '../../../Layout/layoutReducer';
import './Interview.css'

function Interview({ id, data }) {
  const dispatch = useDispatch();

  const formsubmissionstate = useSelector(state => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);
  const successMsg = useSelector(state => state.formSlice.message?.InterviewAddEdit)//for snackbar massge
  const successStatus = useSelector(state => state.formSlice.status?.InterviewAddEdit)

  const interviewform = {

    id: 'InterviewAddEdit',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "users/name-interview-data-add-update",
    // urlPathOnSuccessfulFormSubmission: "",
    // submitBtnName: (editdata !== null) ? "Update" : "Submit",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    endPointBodyWrapper: "data",
    formAdditionalSubmissionData: {
      user_id: (id !== undefined && id !== null) ? id : undefined
    },

    fields: [
      {
        id: 0,
        heading: "Full Name",
        name: "firstname",
        className: "inputBlock1line",
        type: "text",
        // rules: { required: true },
        // errorMessage: {
        //   required: "Fast Name is Required",
        // },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (data && Object.keys(data).length > 0 && data.firstname !== undefined) ? data.firstname : '',

      },

      {
        id: 1,
        heading: "Email",
        name: "email",
        className: "inputBlock1line",
        type: "text",
        // rules: { required: true },
        // // disabled: true,
        // errorMessage: {
        //   required: "Email is Required",
        // },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (data && Object.keys(data).length > 0 && data.email !== undefined) ? data.email : '',

      },

      {
        id: 2,
        heading: "Phone",

        name: "phone",
        className: 'inputBlock1line',
        type: "text",
        inputType: "phone",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        // rules: { required: true },
        // errorMessage: {
        //   required: "Phone No is Required",
        // },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (data && Object.keys(data).length > 0 && data.phone !== undefined) ? data.phone : '',


      },
      {
        id: 3,
        type: "html",
        className: "heading blue_text",
        value: "<h1>Beginning of the Call:</h1>"
      },
      {
        id: 4,
        type: "html",
        className: "paragraph blue_text",
        value: "<p>1. Relational/Small talk</p>"
      },
      {
        id: 5,
        type: "html",
        className: "paragraph blue_text",
        value: "<p>2. Confirm it is okay to record the call</p>"
      },
      {
        id: 6,
        type: "html",
        className: "paragraph blue_text",
        value: "<p>3. Outline goal of call (get better acquainted so by end of call we know if we want to take the next steps together)</p>"
      },
      {
        id: 27,
        type: "html",
        className: "heading",
        value: "<h1>Part 1. Background - Tell me a little bit about yourself</h1>"
      },
      {
        id: 7,
        heading: "Give me a quick 90 second summary of your background. What are you looking to do now? *",
        // label: "Address",
        name: "summary",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        defaultValue: (data && Object.keys(data).length > 0 && data.summary !== undefined) ? data.summary : '',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 8,
        type: "html",
        className: "heading",
        value: "<h1>Part 2. Why Aspire?</h1>"
      },
      {
        id: 9,
        heading: "Did you get a chance to watch our video? *",
        name: "video_response",
        className: 'radio title_sub_wrp',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue: (data && Object.keys(data).length > 0 && data.video_response !== undefined) ? data.video_response : '',
        // defaultValue: "No"
      },
      {
        id: 28,
        heading: "What is it about Aspire that interests you? Other ways to ask…Why Aspire? What was it about Aspire that attracted you? How do you want to help companies?) *",
        // label: "Address",
        name: "description",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        rules: { required: true },
        defaultValue: (data && Object.keys(data).length > 0 && data.description !== undefined) ? data.description : '',
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },


      {
        id: 10,
        type: "html",
        className: "heading",
        value: "<h1>Part 3. Qualified Relationships</h1>"
      },
      {
        id: 29,
        type: "html",
        className: "paragraph",
        value: "<p>How many established relationships do you have with C-level executives/decision-makers/business owners?</p>"
      },
      {
        id: 11,
        heading: "Talk me through the first 2 people you would reach out to (get a feel for their relationships) *",
        // label: "Address",
        name: "relationships",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        rules: { required: true },
        defaultValue: (data && Object.keys(data).length > 0 && data.relationships !== undefined) ? data.relationships : '',
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 12,
        type: "html",
        className: "heading",
        value: "<h1>Part 4. Questions/Comments</h1>"
      },
      {
        id: 13,
        heading: "Do you have any questions for me?",
        label: "Comments",
        name: "comments",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        defaultValue: (data && Object.keys(data).length > 0 && data.comments !== undefined) ? data.comments : '',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 14,
        type: "html",
        className: "heading",
        value: "<h1>Part 5. Discuss Next Steps (if moving forward)</h1>"
      },
      {
        id: 15,
        type: "html",
        className: "paragraph",
        value: "<p>1. Send you our Agreement (it's an NDA with a right to represent, spells out your role, compensation, willable, etc.)</p>"
      },
      {
        id: 16,
        type: "html",
        className: "paragraph",
        value: "<p>2. Our next National Call is ___________ at 1pm ET. You will receive an invite once you sign the Agreement</p>"
      },
      {
        id: 17,
        type: "html",
        className: "paragraph",
        value: "<p>3. Sign Agreement. As soon as you do, you'll receive an email with credentials to Aspire Member Center</p>"
      },
      {
        id: 18,
        type: "html",
        className: "paragraph",
        value: "<p>4. That will give you access to Training for all services</p>"
      },
      {
        id: 19,
        type: "html",
        className: "paragraph",
        value: "<p>5. Please take a look at the Start Here section as that gives you a great orientation to Aspire and the  training section. There is a video and a document you'll find helpful.</p>"
      },
      {
        id: 20,
        type: "html",
        className: "paragraph",
        value: "<p>6. Please plan to join our National Call on Wednesday</p>"
      },
      {
        id: 21,
        type: "html",
        className: "paragraph",
        value: "<p>7. Look for the email with the Agreement from me and then you will also receive an email with the National Call information.</p>"
      },
      {
        id: 22,
        type: "html",
        className: "paragraph",
        value: "<p>8. Do you have any last questions?</p>"
      },
      {
        id: 30,
        type: "html",
        className: "paragraph",
        value: "<p>9. I've enjoyed talking to you and look forward to working with you. Please reach out if you have any questions</p>"
      },


      {
        id: 23,
        heading: "",
        // label: "Address",
        name: "dns",
        className: 'inputBlock addressbox inputBlock1line title_sub_wrp',
        type: "textarea",
        //inputType: "number",/////////////// If not mentioned default will be text //////////////
        // rules: { required: true, maxLength: 20, validate: { isValidLastname } },
        defaultValue: (data && Object.keys(data).length > 0 && data.dns !== undefined) ? data.dns : '',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },

      {
        id: 24,
        heading: "Second Interview Needed? *",
        name: "second_interview",
        className: 'radio title_sub_wrp',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: "No"
        defaultValue: (data && Object.keys(data).length > 0 && data.second_interview !== undefined) ? data.second_interview : '',

      },
      {
        id: 25,
        heading: "Was position offered? *",
        name: "position_offered",
        className: 'radio title_sub_wrp',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: "No"
        defaultValue: (data && Object.keys(data).length > 0 && data.position_offered !== undefined) ? data.position_offered : '',

      },
      {
        id: 26,
        heading: "Was position accepted? *",
        name: "position_accepted",
        className: 'radio title_sub_wrp',
        values: [{ key: 1, val: 'Yes' }, { key: 0, val: 'No' },],
        type: 'radio',
        hint: 'Yes/No',
        defaultValue: (data && Object.keys(data).length > 0 && data.position_accepted !== undefined) ? data.position_accepted : '',
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: "No"
      },



    ]
  }
  useEffect(() => {
    if (formsubmissionstate.InterviewAddEdit === 2) {
      console.log("formsubmissionstate............", formsubmissionstate)
      // setOpenSnacAddEdit(true)

      store.dispatch(
        setSnackbar({ open: true, message: successMsg, status: successStatus })
      )

    }

    console.log("true==========>");
  }, [formsubmissionstate])


  return (
    <>
      <h1 className='page_heading'>Rep Interview Form </h1>

      <div className='interview_wrp'>
        {/* <Form formData={interviewform} /> */}
        {id !== undefined && data && (data.length > 0 || Object.keys(data).length > 0) ? <> <Form formData={interviewform} /> </> : <Form formData={interviewform} />}
      </div>
    </>
  )
}

export default Interview
