import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import { getroledata, clearroledata, getfaqcategorydata, geteditfaqcategorydata, cleareditfaqcategorydata } from './FaqReducer';
import store from '../../../../store';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { resolvefunction } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer';


function AddEditFaqCategory() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [rolArry, setrolArry] = useState([]);
    const navigate = useNavigate()
    const pathname = useLocation();

    let reqbodyforlead = {
        "condition": {
            "limit": 5,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": {},
        "project": {},
        "token": ""
    }
    let reqbodyforrole = {
        "condition": {
            "limit": 100,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "status": 1 },
        "project": {},
        "token": ""
    }

    const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
    let roledata = useSelector(state => (state.FaqReducer?.roledata && state.FaqReducer?.roledata?.length > 0) ? state.FaqReducer.roledata : []);
    const goBackClicked = useSelector(state => state.formSlice?.customButtonClicked?.faqCategoryAddEditForm?.faqgoback002 ? state.formSlice.customButtonClicked.faqCategoryAddEditForm.faqgoback002 : false);
    const submitbtnclick = useSelector(state => state.formSlice.formSubmissionState.faqCategoryAddEditForm ? state.formSlice.formSubmissionState.faqCategoryAddEditForm : 0)
    const editdata = useSelector(state => (state.FaqReducer?.editfaqcategorydata && state.FaqReducer?.editfaqcategorydata.length > 0) ? state.FaqReducer.editfaqcategorydata[0] : null);
    const resolved = useSelector(state => state.ResolveReducer?.resolved[`/edit-faqcategory/${id}`] ? state.ResolveReducer.resolved[`/edit-faqcategory/${id}`] : false)

    console.log("submitbtnclick", submitbtnclick);

    useEffect(() => {
        store.dispatch(getroledata(reqbodyforrole));
        if (!resolved) {

            if (id !== undefined && pathname !== null && pathname.pathname !== undefined) {
                console.log("ppp>>", editdata)
                store.dispatch(geteditfaqcategorydata(id));

            }

        }

        return () => {
            store.dispatch(cleareditfaqcategorydata())
            store.dispatch(resetresolvenew('/edit-faqcategory/:id'))
        }
    }, [])
    console.log("editdata", editdata);


    useEffect(() => {
        if (id !== undefined && id !== null && editdata !== null) {
            if (submitbtnclick == 2) {
                console.log("submitbtnclick", submitbtnclick);
                dispatch(setSnackbar({ open: true, message: "Update Successfully !", status: 'success' }))
            };
        }
        else {
            if (submitbtnclick == 2) {
                console.log("submitbtnclick", submitbtnclick);
                dispatch(setSnackbar({ open: true, message: "Add Successfully !", status: 'success' }))
            };
        }

       

    }, [submitbtnclick])

    useEffect(() => {
        if (goBackClicked) {
            resolvefunction(navigate, '/faq-category', getfaqcategorydata(reqbodyforlead))
        }

    }, [goBackClicked])
    // useEffect(() => {
    //     store.dispatch(getroledata(reqbodyforrole));

    // }, [])

    useEffect(() => {

        if (roledata && roledata.length > 0) {
            let arr = [];
            for (let i in roledata) {
                arr.push({ val: roledata[i]._id, name: roledata[i].name })
            }
            setrolArry(arr)
        }

    }, [roledata])
    console.log("roledata---->>", rolArry);



    const dataform = {
        id: 'faqCategoryAddEditForm',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "manage-faq-category/add-update-faq-category",
        urlPathOnSuccessfulFormSubmission: "/faq-category",
        submitBtnName: "Submit",
        cancelBtnName: "Cancel",
        formButtonClass: "Formsubmit_button",
        formWrapperClass: "backend_addform addAdmin",
        formInlineClass: "backendTableInline_addform testwrpr trainingcata",
        formAdditionalSubmissionData: {

            _id: (id !== undefined && id !== null) ? id : undefined,
            added_by: user_id
        },

        fields: [
            {
                id: 0,
                heading: "Category Name",
                label: "Category Name",
                name: "category_name",
                // className: "inputBlock inputBlock1line",
                className: 'inputBlock inputBlock1line',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                },
                //   minLength: "Minimum length should be 15",
                //   custom: "Value is Invalid"
                defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.category_name !== undefined) ? editdata.category_name : undefined,

            },
            {
                id: 1,
                heading: "Description",
                label: "SunEditor",
                name: "desc",
                type: "sunEditor",
                className: "inputBlock1line",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    //   minLength: "Minimum length should be 15",
                    //   custom: "Value is Invalid"
                },

                placeholder: "Please type here...",
                ////////////////////////////// Mandatory or no options will be available /////////////////////
                editorWidth: 300,
                buttonListOptions: [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                ],
                defaultValue: (editdata && editdata.desc !== undefined) ? editdata.desc : undefined,

            },
            {
                id: 3,
                heading: "Select User Roll",
                label: "Select User Roll",
                name: "user_role",
                className: ' inputBlock inputBlock2line',
                type: 'select',
                // multiple: true,
                checkbox: false,
                other: false,
                rules: { required: true },
                values: rolArry,
                defaultValue: (editdata && editdata.user_role !== undefined) ? editdata.user_role : undefined,
                errorMessage: {
                    required: "This Field is Required",
                },

            },

           

            {
                id: 4,
                heading: "Priority",
                label: "priority",
                name: "priority",
                className: 'inputBlock inputBlock2line',
                type: "text",
                inputType: "number",/////////////// If not mentioned default will be text //////////////
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },
                defaultValue: (editdata && editdata.priority !== undefined) ? editdata.priority : undefined,
            },
            {
                id: 5,
                // heading: "Status",
                // label: "Status",
                name: "status",
                className: 'inputBlock1line',
                type: 'singleCheckbox',
                values: { key: 1, val: 'Active' },
                // rules: { required: true },
                // errorMessage: {
                //   required: "This Field is Required",
                // },
                defaultValue: (editdata && editdata.status !== undefined) ? Boolean(editdata.status) : false,

            },


        ],
        customButtons: [
            {
                id: 'faqgoback002',
                label: 'Go Back',
                className: 'default',
                type: 'button',
            },
        ]


    }

  return (
    <>
          <h1 className='page_heading'>ADD FAQ CATEGORY</h1>
          <div className="dataform2_wrapper">
              <div className='adminformbody'>
                  {id !== undefined && id !== null && editdata !== null ? <Form formData={dataform} /> : (id === undefined ? <Form formData={dataform} /> : '')}
              </div>
          </div>
    </>
  )
}

export default AddEditFaqCategory
